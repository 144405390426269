import { Base } from "./base"

class Intercom extends Base {
    constructor({ utils, maxAttemps, globalEvents, xhrTransport, visitor, JSAPI }) {
        super({ globalEvents, name: "Intercom" })
        this.utils = utils
        this.maxAttemps = maxAttemps
        this.xhrTransport = xhrTransport
        this.visitor = visitor
        this.JSAPI = JSAPI

        this.inited = false
        this.propsSended = false
        this.properties = {
            profile_link: false,
            event_link: false,
        }
    }

    check() {
        if ("function" == typeof window.Intercom) {
            const visitorID = window.Intercom("getVisitorId")
            if (!visitorID) return false
            this.setProps({
                visitor_id: visitorID,
            })

            if (this.props["link.event"]) {
                this.JSAPI.getSessionURL((sessionURL, isNew) => {
                    isNew && window.Intercom("trackEvent", "LiveSession", { sessionURL: sessionURL })
                })
            }
            if (this.props["link.profile"]) {
                this.JSAPI.getSessionURL((sessionURL, isNew) => {
                    isNew && window.Intercom("update", { livesessionURL: sessionURL })
                })
            }
            return true
        }
        return false
    }
}

export { Intercom }
