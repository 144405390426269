import { DOMHandler } from "./handler"
import { safe } from "./../../common/tools"
import { events } from "../events/typedef"

class MouseClickHandler extends DOMHandler {
    constructor({ utils, eventsStream, mouseMoveHandler, touchMoveHandler, windowScrollHandler }) {
        super()
        this.utils = utils
        this.eventsStream = eventsStream

        this.windowScrollHandler = windowScrollHandler
        this.mouseMoveHandler = mouseMoveHandler
        this.touchMoveHandler = touchMoveHandler

        this.eventFunc = (e) => safe(this.Handler.bind(this), e)
        this.eventAfterFunc = (e) => safe(this.AfterHandler.bind(this), e)
        this.errorFunc = (e) => safe(this.onError.bind(this), e)

        // errorClicks - chceck if error between clicks (window.onerror)
        // deadClicks - getEventListeners  - for () e.paths; max 100; a href/clicks/button in forms; devtools only :(
        // rageClicks - click on same element > 3 times; event.detail > 3
        // clickedText - target.textContent
    }

    Register() {
        window.addEventListener("click", this.eventFunc, true)
        window.addEventListener("click", this.eventAfterFunc, false)
        window.addEventListener("error", this.errorFunc, true)
    }

    Unregister() {
        window.removeEventListener("click", this.eventFunc, true)
        window.removeEventListener("click", this.eventAfterFunc, false)
        window.removeEventListener("error", this.errorFunc, true)
    }

    onError(e) {
        if (!e) return
        const { error = {} } = e
        if (!error) return
        this.err = {
            message: error.message,
            stack: error.stack || error.backtrace || error.stacktrace,
        }
    }

    AfterHandler(e) {
        if (e.isTrusted !== undefined && !e.isTrusted) return
        if (e.screenX == 0 && e.screenY == 0) return

        if (this.err) {
            this.eventsStream.Add(events.ERROR_CLICK, {
                x: e.pageX,
                y: e.pageY,
                path: this.utils.DOM.FullPathV2(e),
                txt: this.utils.DOM.Content(e.target),
                el: this.utils.DOM.ElementName(e.target),
                value: this.err.message,
                json_data: this.err,
            })
        }
    }

    handleRageClick(e) {
        if (this.rageClickTimeout != -1) {
            clearTimeout(this.rageClickTimeout)
            this.rageClickTimeout = -1
        }
        this.rageClickTimeout = setTimeout(() => {
            this.eventsStream.Add(events.RAGE_CLICK, {
                x: e.pageX,
                y: e.pageY,
                path: this.utils.DOM.FullPathV2(e),
                txt: this.utils.DOM.Content(e.target),
                el: this.utils.DOM.ElementName(e.target),
                json_data: { clicks: e.detail },
            })
        }, 300)
    }

    Handler(e) {
        if (e.isTrusted !== undefined && !e.isTrusted) return
        if (e.screenX == 0 && e.screenY == 0) return

        // call mouse pos and window scroll before click
        this.windowScrollHandler.Trigger()
        if (this.utils.getDeviceType() == "Desktop") {
            this.mouseMoveHandler.Trigger()
        } else {
            this.touchMoveHandler.Trigger()
        }

        if (e.detail > 3) {
            this.handleRageClick(e)
        }

        const evt = {
            x: e.pageX,
            y: e.pageY,
            path: this.utils.DOM.FullPathV2(e),
            txt: this.utils.DOM.Content(e.target),
            el: this.utils.DOM.ElementName(e.target),
        }
        this.err = null
        this.eventsStream.Add(this.name, evt)
    }
}

export { MouseClickHandler }
