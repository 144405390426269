import forEach from "for-each"
import { Handler, IHandler } from "./handler"
import { getFullPath } from "../../helpers/urlHelper"
import { events } from "../events/typedef"
import { IHooks } from "../hooks.ts"
import { IUtils } from "../utils/Utils"
import { IOptions } from "../Options"
import { IEventsStream } from "../events/stream"
import { IEventsListener } from "../../common/eventListener"
import { toObject, bytesSize } from "../../helpers/stringHelper"
import { allowRule } from "../events/typedef"
import { debug, safe } from "./../../common/tools"

const ctxLog = (...args) => {
    debug("DevtoolsNetwork", ...args)
}

interface INetwork {
    Register(): void
    Unregister(): void
}

type allowType = allowRule.include | allowRule.exclude

interface INetworkAllowList {
    url_pattern: string
    rule: allowType
    request_body: boolean | null
    response_body: boolean | null
}

interface IRequest {
    id: string
    request: { _lsid: string; headers: object; body: string | null }
    response: { _lsid: string; headers: object; body: string; size: number; responseType: string; responseText: string; length: number }
    method: string
    url: string
    startTime: number
}

interface INetworkEvent {
    request: { _lsid: string; headers: object; body: string | null; bodyExists: boolean }
    response: { _lsid: string; headers: object; body: string; bodyExists: boolean; size: number; responseType: string; responseText: string; length: number }
    status: number
    time: number
    methodid: string
    method: string
    url: string
    startTime: number
    type: string
    headers: []
}

interface IEvent {
    type: string
    obj: any
    time: number
    json_data: INetworkEvent
}

const matchUrl = (regexUrl: string, url: string): boolean => {
    try {
        if (url?.toLowerCase().match(regexUrl?.toLowerCase())) {
            return true
        }
    } catch (ex) {}
    return false
}

const isAllow = (list: INetworkAllowList[], url: string): { allowLog: boolean; allowLogRequestBody: boolean; allowLogResponseBody: boolean } => {
    try {
        const includeExist = list?.filter((x) => x.rule === allowRule.include).length > 0
        const excludeExist = list?.filter((x) => x.rule === allowRule.exclude).length > 0
        if (!list.length || !includeExist) {
            return { allowLog: true, allowLogRequestBody: false, allowLogResponseBody: false }
        }
        let matchIncluded = false
        let matchExcluded = false
        let allow = null
        let allowLogRequestBody: null | boolean = null
        let allowLogResponseBody: null | boolean = null

        list.forEach((element) => {
            if (matchUrl(element?.url_pattern, url)) {
                switch (element.rule) {
                    case allowRule.include: {
                        matchIncluded = true
                        if (allowLogRequestBody !== false) {
                            allowLogRequestBody = element?.request_body
                        }
                        if (allowLogResponseBody !== false) {
                            allowLogResponseBody = element?.response_body
                        }
                    }
                    case allowRule.exclude: {
                        matchExcluded = true
                    }
                    default: {
                        break
                    }
                }
            }
        })

        if (allow === null) {
            if (!excludeExist) {
                allow = matchIncluded
            } else {
                allow = matchIncluded && !matchExcluded
            }
        }
        return { allowLog: allow, allowLogRequestBody: !!allowLogRequestBody, allowLogResponseBody: !!allowLogResponseBody }
    } catch (ex) {
        ctxLog(ex)
    }
    return { allowLog: true, allowLogRequestBody: false, allowLogResponseBody: false }
}

class Network extends Handler implements IHandler {
    private hooks: IHooks
    private utils: IUtils
    private options: IOptions
    private eventsStream: IEventsStream
    private globalEvents: IEventsListener
    private maxBodyLength: number
    private maxHeaderLength: number
    private maxErrorsPerPage: number
    private networkAllowList: INetworkAllowList[]

    private watchedEvents: { setRequestHeader: any; open: any; send: object }
    private buff: IEvent[]
    private pendingReqs: IRequest[]
    private enabled: boolean
    private inited: boolean
    private mask: string

    private NetErrorCounter: number
    private NetLogCounter: number
    private logCounter: number
    private errCounter: number

    private logReached: boolean
    private errReached: boolean
    private NetErrorReached: boolean
    private NetLogReached: boolean

    constructor({
        utils,
        eventsStream,
        options,
        hooks,
        globalEvents,
    }: {
        utils: IUtils
        eventsStream: IEventsStream
        options: IOptions
        hooks: IHooks
        globalEvents: IEventsListener
    }) {
        super()
        this.utils = utils
        this.options = options
        this.eventsStream = eventsStream
        this.hooks = hooks
        this.globalEvents = globalEvents
        this.networkAllowList = []

        this.maxBodyLength = 2048
        this.maxHeaderLength = 1024
        this.maxErrorsPerPage = 256

        this.watchedEvents = {}
        this.buff = []
        this.pendingReqs = {}
        this.enabled = false
        this.inited = false
        this.mask = "[omitted]"

        this.logCounter = 0
        this.errCounter = 0
        this.NetLogCounter = 0
        this.NetErrorCounter = 0

        this.NetErrorReached = false
        this.NetLogReached = false
        this.logReached = false
        this.errReached = false
    }

    logIsAllow(url: string): { allowLog: boolean; allowLogRequestBody: boolean; allowLogResponseBody: boolean } {
        try {
            const fullpath = getFullPath(url)
            if (fullpath.indexOf(process.env.API_URL) > -1) {
                return { allowLog: false, allowLogRequestBody: false, allowLogResponseBody: false }
            }
            return isAllow(this.networkAllowList, fullpath)
        } catch (ex) {
            ctxLog(ex)
        }
        return { allowLog: false, allowLogRequestBody: false, allowLogResponseBody: false }
    }

    HandleRequests() {
        if (window.fetch && window.Promise) {
            // https://stackoverflow.com/questions/63122604/how-can-i-execute-a-function-every-time-fetch-is-used-in-javascript
            const _this = this
            const _fetch = window.fetch
            window.fetch = function (...args) {
                let [url, request] = args
                if (!request) {
                    request = new Request(url || "")
                }
                const id = _this.addPendingReq(request, request?.method, url || "")
                return Promise.resolve(_fetch.apply(window, args))
                    .then(async (resp) => {
                        let body = null
                        if (resp) {
                            const respClone = resp?.clone()
                            body = await respClone.text().then((text) => text)
                        }
                        return { resp, body }
                    })
                    .then((data) => {
                        if (id) {
                            let respClone = data?.resp?.clone()
                            if (!respClone) respClone = new Response()
                            respClone._lsid = id
                            respClone.size = bytesSize(data?.body)
                            respClone.responseType = "text"
                            respClone.responseText = data?.body
                            _this.onComplete(respClone, events.NET_LOG, "fetch")
                            return data?.resp
                        }
                    })
                    .catch((error) => {
                        if (id) {
                            const response = new Response()
                            response._lsid = id
                            response.size = 0
                            response.responseText = ""
                            _this.onComplete(response, events.NET_ERROR, "fetch")
                        }
                    })
            }
        }
        if (window.XMLHttpRequest) {
            if (this.hooks.CanBind(window.XMLHttpRequest.prototype, "open")) {
                const _this = this
                this.watchedEvents.setRequestHeader = this.hooks.Create(window.XMLHttpRequest.prototype, "setRequestHeader", {
                    before: function beforesetRequestHeader(h, t) {
                        _this.addHeader(this, h, t)
                    },
                })
                this.watchedEvents.open = this.hooks.Create(window.XMLHttpRequest.prototype, "open", {
                    before: function beforeXhr(method: string, url: string) {
                        if (!_this.logIsAllow(url).allowLog) {
                            return
                        }
                        const id = _this.addPendingReq(this, method, url)
                        if (id) {
                            this.addEventListener("error", () => {
                                _this.onComplete(this, events.NET_ERROR, "xhr")
                            })
                            this.addEventListener("load", () => {
                                _this.onComplete(this, events.NET_LOG, "xhr")
                            })
                        }
                    },
                })
                this.watchedEvents.send = this.hooks.Create(window.XMLHttpRequest.prototype, "send", {
                    before: function beforeXhr(body: string) {
                        if (body && this?._lsid && _this.pendingReqs[this._lsid]) {
                            _this.pendingReqs[this._lsid].request.body = _this.getRequestBody({ body })
                        }
                    },
                })
            }
        }
    }

    Register() {
        this.NetErrorCounter = 0
        this.NetLogCounter = 0

        this.logReached = false
        this.errReached = false

        // init handler after receiving settings from API
        this.globalEvents.once("api.session.inited", ({ settings }) => {
            const { networkAllowList } = settings
            this.inited = true
            this.enabled = settings.networkLogs
            this.networkAllowList = networkAllowList?.list || []
            if (this.enabled) {
                this.HandleRequests()
            }
            if (!this.enabled) {
                this.buff = []
                return
            }
            if (this.buff.length) {
                forEach(this.buff, (v: IEvent) => {
                    if (this.logIsAllow(v?.json_data?.url).allowLog) {
                        if (this.isMax(v.type, this.maxErrorsPerPage)) return null
                        this.eventsStream.Add(v.type, v.obj, v.time)
                    }
                })
                this.buff = []
            }
        })
    }

    filterHeaders = (obj) => {
        const filters = ["authorization", "proxy-authorization", "cookie"]

        for (const [key, value] of Object.entries(obj)) {
            if (filters.includes(key.toLowerCase())) {
                obj[key] = this.mask
            } else {
                if (value?.length > this.maxHeaderLength) {
                    obj[key] = `${value?.slice(0, this.maxHeaderLength)} [...]`
                }
            }
        }
        return obj
    }

    ab2str = (buf: ArrayBuffer): string => {
        let result = ""
        if (!("TextDecoder" in window)) {
            const enc = new TextDecoder("utf-8")
            const arr = new Uint8Array(buf)
            result = enc.decode(arr)
        }
        return result
    }

    getResponseBody(request): string {
        let r = ""
        try {
            switch (request.responseType) {
                case "arraybuffer":
                    r = this.ab2str(request?.response)
                case "json":

                case "blob":
                    r = request?.response
                    break
                case "document":
                    r = request?.responseXML
                    break
                case "text":
                case "":
                    r = request?.responseText
                    break
                default:
                    r = ""
            }
        } catch (e) {
            r = `LiveSession: Error accessing response. ${e}`
        }
        // It is possible that responseType is set to some particular value but the server returns response incompatible with that format.
        // In those cases, the value of response will be null.
        if (r == null) {
            r = ""
        }
        if (r.length > this.maxBodyLength) {
            r = `${r.slice(0, this.maxBodyLength)} [...]`
        }
        return r?.toString()
    }

    getResponseLength(response: Response): number {
        let r = 0
        try {
            switch (response.responseType) {
                case "json":
                case "arraybuffer":
                case "blob":
                    r = response.response ? bytesSize(response?.response) : 0
                    break
                case "document":
                    r = response.responseXML ? bytesSize(response?.responseXML) : 0
                    break
                case "text":
                case "":
                    r = response?.responseText ? bytesSize(response?.responseText) : 0
                    break
                default:
                    r = 0
            }
        } catch (e) {
            console.error(`getResponseLength: ${e}`)
        }
        return r
    }

    getRequestBody(request: object): string | null {
        if (!request) return null
        let body = ""
        try {
            if (request?.body) {
                body = request.body
            }
        } catch (e) {
            body = "LiveSession: Error accessing request."
        }
        if (body.length > this.maxBodyLength) {
            body = `${body.slice(0, this.maxBodyLength)} [...]`
        }
        return body
    }

    genRequestID() {
        return this.utils.UUID()
    }

    onComplete(response: XMLHttpRequest | Response, event: string, type: string) {
        const reqID = response._lsid
        if (!reqID || !this.pendingReqs[reqID]) return

        let convertedRespnse = {}
        if (response instanceof XMLHttpRequest) {
            convertedRespnse = this.mapResponseXHR(response)
        }
        if (response instanceof Response) {
            convertedRespnse = this.mapResponseFETCH(response)
        }

        this.pendingReqs[reqID] = {
            ...this.pendingReqs[reqID],
            status: response.status,
            time: Date.now() - this.pendingReqs[reqID].startTime,
            response: convertedRespnse,
        }
        const obj = this.pendingReqs[reqID]

        const networkEvent: INetworkEvent = obj
        networkEvent.type = type
        if (event === events.NET_ERROR) {
            networkEvent.status = 0
        }
        if (networkEvent.status >= 400) {
            event = events.NET_ERROR
        }

        networkEvent.request.headers = this.filterHeaders(networkEvent.request.headers)
        networkEvent.response.headers = this.filterHeaders(networkEvent.response.headers)
        networkEvent.url = getFullPath(networkEvent.url)
        const { allowLog, allowLogRequestBody, allowLogResponseBody } = this.logIsAllow(networkEvent.url)
        if (allowLog) {
            if (!allowLogRequestBody && networkEvent.request.body) {
                networkEvent.request.bodyExists = !!networkEvent.request.body
                networkEvent.request.body = this.mask
            }
            if (!allowLogResponseBody && networkEvent.response.body) {
                networkEvent.response.bodyExists = !!networkEvent.response.body
                networkEvent.response.body = this.mask
            }
            this.addEvent(event, { json_data: networkEvent })
        }

        delete this.pendingReqs[reqID]
        // console.warn({ evt, req: obj })
    }

    mapResponseFETCH(response: Response) {
        const body = this.getResponseBody(response)
        const length = response.size
        const headersObj = {}
        response.headers.forEach((value, name) => (headersObj[name] = value))

        return {
            headers: headersObj,
            length,
            body,
        }
    }

    mapResponseXHR(response: XMLHttpRequest) {
        return {
            headers: this.mapHeaders(response.getAllResponseHeaders()),
            length: this.getResponseLength(response),
            body: this.getResponseBody(response),
        }
    }

    mapHeaders(headers: string): object {
        return toObject(headers)
    }

    addHeader(xhr, h, t) {
        const reqID = xhr._lsid
        if (!reqID || !this.pendingReqs[reqID]) return

        this.pendingReqs[reqID].request.headers[h] = t
    }

    addPendingReq(request: object, method: string, url: string): string | null {
        let reqID = request._lsid
        if (reqID) {
            delete this.pendingReqs[reqID]
        }
        reqID = this.genRequestID()

        this.pendingReqs[reqID] = {
            id: reqID,
            request: { _lsid: reqID, headers: request?.headers ? request?.headers : {}, body: this.getRequestBody(request) },
            method: method && method.toUpperCase(),
            url,
            startTime: Date.now(),
        }
        request._lsid = reqID
        return reqID
    }

    addEvent(type: string, obj: any) {
        const duration: number = this.utils.Time.Duration()
        if (this.enabled) {
            if (this.isMax(type, this.maxErrorsPerPage)) return null
            this.eventsStream.Add(type, obj, obj.startTime, duration)
        }
        if (!this.inited) {
            this.buff.push({ type, obj, time: duration })
        }
    }

    Unregister() {
        this.watchedEvents.open && this.hooks.Unbind(this.watchedEvents.open)
        this.watchedEvents.setRequestHeader && this.hooks.Unbind(this.watchedEvents.setRequestHeader)
    }

    isMax(type: "NetError" | "NetLog" | "log" | "err", limit: number): boolean {
        if (this[`${type}Counter`] >= limit) {
            if (!this[`${type}Reached`]) {
                this[`${type}Reached`] = true
                this.addEvent(events.NET_LOG, {
                    value: "internal",
                    json_data: { value: `tracking.network.maxLogs.${type}` },
                })
            }
            return true
        }
        this[`${type}Counter`]++
        return false
    }

    open(method, url) {
        console.warn({ method, url })
    }
}

export { Network, INetwork, isAllow, matchUrl, INetworkAllowList }
