import forEach from "for-each"

import { Handler } from "./handler"
import { TreeMirrorClient } from "../../ext/tree-mirror-client"
import { Ticker } from "../../common/ticker"
import { isEmpty } from "./../../helpers/objectHelper"
import { events } from "../events/typedef"
import { debug, safe } from "../../common/tools";
import { CSSChangeHandler } from "./cssChange";

const ctxLog = (...args) => {
    debug("dom-mutation", ...args)
}

// TODO: in the future replace with only DocumentOrShadowRoot = adoptedStyleSheets property is available in rc version of ts types
interface IDocumentOrShadowRoot extends DocumentOrShadowRoot {
    adoptedStyleSheets: CSSStyleSheet[];
}

class DOMMutationHandler extends Handler {
    constructor({ utils, eventsStream, globalEvents, inputWatcher, formWatcher, hooks, aquireInterval, pageView, handlers }) {
        super()

        this.utils = utils
        this.eventsStream = eventsStream
        this.globalEvents = globalEvents
        this.inputWatcher = inputWatcher
        this.formWatcher = formWatcher
        this.hooks = hooks
        this.handlers = handlers

        this.cachedAttrs = {}
        this.resourcesAdded = {}
        this.ticker = new Ticker({ interval: aquireInterval, fn: this.onTick.bind(this) })
        this.pageView = pageView
        this.watchedEvents = {}
    }

    Register() {}

    Unregister() {
        if (this.mirror) this.mirror.disconnect()
        this.watchedEvents.attachShadow && this.hooks.Unbind(this.watchedEvents.attachShadow)
    }

    onTick() {
        if (!isEmpty(this.cachedAttrs)) {
            this.addEvent([], [], this.getAndFlushCachedAttrs(), [])
        }
    }

    // watch for CSS/Fonts/Images resources
    watchResoucesNodes(nodes) {
        const addOnce = (type, href, url) => {
            if (!this.resourcesAdded[href]) {
                this.eventsStream.Add(events.RESOURCE_ADDED, {
                    json_data: {
                        type: type,
                        href: href,
                        base: this.pageView.GetBaseURL(),
                        url: url,
                    },
                })
                this.resourcesAdded[href] = true
            }
        }
        for (let i = 0, l = nodes.length; i < l; i++) {
            const elem = nodes[i]
            switch (elem.tN) {
                case "LINK":
                    if (elem.a.rel) {
                        switch (elem.a.rel) {
                            case "font":
                            case "stylesheet":
                                let url = ""
                                try {
                                    const u = new URL(elem.a.href, window.location.href)
                                    url = u.href
                                } catch (e) {
                                    continue
                                }
                                addOnce(elem.a.rel, elem.a.href, url)
                                break
                        }
                    }
                    break
                case "IMG":
                    if (elem.a["data-ls-disabled"]) {
                        continue
                    }
                    let url = ""
                    try {
                        const u = new URL(elem.a.src, window.location.href)
                        url = u.href
                    } catch (e) {
                        continue
                    }
                    addOnce("image", elem.a.src, url)
                    break
            }
        }
    }

    Handler(removed, addedOrMoved, attributes, text) {
        // throttle attributes change
        if (attributes.length > 0) {
            forEach(attributes, (el) => {
                if (!this.cachedAttrs[el.i]) this.cachedAttrs[el.i] = {}
                this.cachedAttrs[el.i] = { ...this.cachedAttrs[el.i], ...el.a }

                if (el.a && el.a["data-ls-mask"]) {
                    const node = this.mirror.knownNodes.nodes[el.i]

                    if (!node) {
                        return
                    }
                    const _this = this

                    function addEvent(w, h) {
                        const attrs = {
                            src: null,
                            "data-ls-img": {
                                w,
                                h,
                            },
                        }

                        _this.addEvent(
                            [],
                            [],
                            [
                                {
                                    i: node._lsid,
                                    a: attrs,
                                },
                            ],
                            [],
                        )
                    }

                    if (node.complete) {
                        addEvent(node.width, node.height)
                        return
                    }

                    node.onload = function () {
                        addEvent(this.width, this.height)
                    }
                }
            })
        }
        if (removed.length > 0 || addedOrMoved.length > 0 || text.length > 0) {
            this.addEvent(removed, addedOrMoved, this.getAndFlushCachedAttrs(), text)
        }
    }

    getAndFlushCachedAttrs() {
        let res = []
        forEach(this.cachedAttrs, (el, idx) => {
            res.push({
                i: idx,
                a: el,
            })
        })
        this.cachedAttrs = {}

        return res
    }

    disableImage(node) {
        if (node.complete) {
            return { w: node.width, h: node.height }
        } else {
            const _this = this
            node.onload = function () {
                _this.addEvent([], [], [{ i: this._lsid, a: { "data-ls-img": { w: this.width, h: this.height } } }], [])
            }
        }
        return null
    }

    applyAttributesNodeData(nodeData) {
        this.addEvent([], [], [nodeData], [])
    }

    addEvent(removed, addedOrMoved, attributes, text) {
        const data = {
            rmvd: removed,
            admd: addedOrMoved,
            attr: attributes,
            txt: text,
        }
        this.watchResoucesNodes(addedOrMoved)
        this.eventsStream.Add(this.name, { json_data: data })
    }

    addShadowListeners(shadowRoot) {
        this.handlers.AddShadowListeners(shadowRoot)
    }

    removeShadowListeners(shadowRoot) {
        this.handlers.RemoveShadowListeners(shadowRoot)
    }

    Init() {
        this.mirror = new TreeMirrorClient(
            document,
            {
                inputWatcher: this.inputWatcher,
                initialize: this.onInitialized.bind(this),
                applyChanged: this.Handler.bind(this),
                visitNode: this.visitNode.bind(this),
                disableImage: this.disableImage.bind(this),
                applyAttributesNodeData: this.applyAttributesNodeData.bind(this),
                addShadowListeners: this.addShadowListeners.bind(this),
                removeShadowListeners: this.removeShadowListeners.bind(this),
                onAdoptedStyleSheets: this.onAdoptedStyleSheets.bind(this)
            },
            null,
            this.utils,
    )

        const self = this

        this.watchedEvents.attachShadow = this.hooks.Create(Element.prototype, "attachShadow", {
            after: function() {
                const host = this

                safe(() => {
                    if (!self.mirror || !host.shadowRoot) {
                        ctxLog("Error", "mirror and shadowRoot should exists")
                        return
                    }
                    self.mirror.attachShadowRoot(host.shadowRoot)
                })
            }
        })

        this.globalEvents.call(`handlers.${this.name}.inited`, this.rootID, this.children, this.mirror)
    }

    // return serialized DOM
    GetPageBody() {
        const body = this.mirror.getSerializedDocument(document)
        return JSON.stringify(body)
    }

    onInitialized(rootID, children) {
        this.rootID = rootID
        this.children = children
    }

    // called during  DOM walkthrough
    visitNode(node: Element, data, mirror) {
        switch (node.tagName) {
            case "LINK":
                const linkNode = node as HTMLLinkElement
                const css = this.processBlobLinkCSS(linkNode)
                if (!css) {
                    return
                }

                const text = document.createTextNode(css)
                // needed for correct serialize node
                const mirrored = node.cloneNode()
                mirrored.appendChild(text)

                const tag = data.tN
                data.tN = "STYLE"
                data.a = {
                    "original-blob-href": data.a.href,
                    "original-tag": tag,
                }
                data.cN = [mirror.serializeNode(text)]

                break
            case "FORM":
                this.formWatcher.registerForm(node, data.i)
                break
            case "INPUT":
            case "TEXTAREA":
            case "SELECT":
                this.inputWatcher.registerInput(node, data.i)
                break
            case "VIDEO":
            case "AUDIO":
                // TODO: play/pause event
                break
        }
    }

    onAdoptedStyleSheets(node: IDocumentOrShadowRoot) {
        if (!node) {
            return
        }

        const { adoptedStyleSheets } = node
        if (!adoptedStyleSheets) {
            ctxLog("adoptedStyleSheets not found")
            return
        }

        const self = this

        const nodeID = self.utils.DOM.GetNodeID(node) as number

        if (nodeID) {
            this.addAdoptedStyleSheets(nodeID, adoptedStyleSheets)
            return
        }

        // hack, if DOMChange should be fired first
        setTimeout(() => {
            const id = self.utils.DOM.GetNodeID(node)
            if (!id) {
                return
            }
            this.addAdoptedStyleSheets(id, adoptedStyleSheets)
        }, 0)
    }

    addAdoptedStyleSheets(id: number, adoptedStyleSheets: CSSStyleSheet[]) {
        const cssChangeHandler: CSSChangeHandler = this.handlers.Get(events.CSS_CHANGE)
        if (!cssChangeHandler) {
            ctxLog("cssChange handler not found")
            return
        }

        cssChangeHandler.addAdoptedStyleSheets(id, adoptedStyleSheets)
    }

    private processBlobLinkCSS(node: HTMLLinkElement): string {
        const isBlob = node.href.startsWith("blob:")
        if (!isBlob) {
            return ""
        }

        let cssRules

        try {
            cssRules = (node.sheet?.cssRules || []) as CSSRule[]
        } catch(e) { // if we can't access css rules (e.g cross domain)
            ctxLog("Error: ", e)
            return ""
        }

        if (!cssRules.length) {
            return ""
        }

        let css = ""
        for (const rule of cssRules) {
            css += `${rule.cssText}\n`
        }

        return css
    }
}

export { DOMMutationHandler }

