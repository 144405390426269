export class EventSeq {
    nextSeq: number = 0

    constructor() {
        this.Reset()
    }

    Reset(): void {
        this.nextSeq = 1
    }

    Next(): number {
        return this.nextSeq++
    }
}
