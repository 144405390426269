import { debug } from "../../common/tools"
import { Ticker } from "../../common/ticker"

class Base {
    constructor({ globalEvents, name }) {
        this.name = name
        this.props = {}
        this.globalEvents = globalEvents
        this.installed = false
        this.enabled = false
        this.ticker = new Ticker({ interval: 1000 })
    }

    debug(...args) {
        debug(this.name, ...args)
    }

    setName(name) {
        this.name = name
    }

    Enable() {
        this.enabled = true
    }
    Disable() {
        this.enabled = false
    }

    setInstalled() {
        this.globalEvents.call("integration.installed", { name: this.name, props: this.props })
        this.installed = true
    }

    setProps(props) {
        this.debug(`props set:`, props)
        this.props = {
            ...this.props,
            ...props,
        }
    }

    isEnabled() {
        return this.enabled
    }

    isInstalled() {
        return this.installed
    }

    scan() {
        if (this.isInstalled()) {
            return
        }
        if (this.check()) {
            this.setInstalled()
        } else {
            let counter = 0
            this.ticker.Start(() => {
                if (counter > this.maxAttemps) this.ticker.Stop()
                if (this.check()) {
                    this.setInstalled()
                    this.ticker.Stop()
                }
                counter++
            })
        }
    }
}

export { Base }
