import { debug } from "./tools"
import { isEqual } from "./../helpers/objectHelper"
var pVersion = require("../../../package.json").version
var pName = require("../../../package.json").name
import { eventFromStacktrace } from "../ext/sentry/parsers"
import { computeStackTrace } from "../ext/sentry/tracekit"
const ctxLog = (...args) => {
    debug("BugTracker", ...args)
}
const arrayContainsElament = (array: Array<object>, element: {} | string): boolean =>
    array.filter(function (e) {
        return isEqual(e, element)
    }).length > 0

class BugTracker {
    limit = 0
    counter = 0
    sentErrors = []
    enabled = false
    constructor({ limit }: { limit: number }) {
        this.limit = limit
        this.counter = 0
        this.sentErrors = []
    }

    send(e) {
        if (!this.enabled) return
        if (process.env.ENV == "localhost") return
        if (this.counter >= this.limit || arrayContainsElament(this.sentErrors, e)) {
            ctxLog("omit error:", e)
            return
        }
        this.counter++
        this.sentErrors.push(e)
        const stackTrace = computeStackTrace(e as Error)
        const event = eventFromStacktrace(stackTrace)
        const releasePostfix = process.env.ENV == "staging" ? "-staging" : ""
        const release = `${pName}@${pVersion}${releasePostfix}`
        const data = {
            ...event,
            message: e.message,
            timestamp: new Date().toISOString(),
            environment: process.env.ENV,
            extra: {
                version: process.env.VERSION,
                account_id: window.__lsAID,
                website_id: window.__lsWID,
            },
            request: {
                url: window?.location?.href,
                headers: {
                    Referer: document?.referrer,
                    "User-Agent": navigator?.userAgent,
                },
            },
            release,
            level: "error",
            platform: "javascript",
        }
        try {
            const xhr = new XMLHttpRequest()

            //xhr.open("POST", "https://sentry.io/api/1248353/store/", true)
            xhr.open("POST", "https://sentry.io/api/6350274/store/", true)
            xhr.setRequestHeader("Content-Type", "application/json")
            //xhr.setRequestHeader("X-Sentry-Auth", "Sentry sentry_version=7, sentry_key=fbd26d85ac62429a8f713397b4c3ec44, sentry_client=raven-bash/0.1")
            xhr.setRequestHeader("X-Sentry-Auth", "Sentry sentry_version=7, sentry_key=b8d30adf717a41c8adddfc9b9d4f428b, sentry_client=raven-bash/0.1")

            xhr.send(JSON.stringify(data))
        } catch (e) {
            ctxLog("Failed to send:", e)
        }
    }
}

export { BugTracker }
