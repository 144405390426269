
class Snapshot {
    constructor(events) {
        this.events = events
    }

    Size() {
        return this.events.length
    }
    IsEmpty() {
        return this.Size() === 0
    }
    Events() {
        return this.events
    }
}

export { Snapshot }
