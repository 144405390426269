import { Handler } from "./handler"
import { debug, safe } from "../../common/tools"
import { Throttle } from "../../common/throttle"

const TYPE_INSERT = 0
const TYPE_DELETE = 1
const TYPE_SNAPSHOT = 2
const TYPE_ADOPTED_STYLESHEET = 3

/*
    TODO:
        * clear adopted stylesheet (if instance destroyed)
        * check what happened if rules are inserted dynamically to the same adoptedstylesheet - if we don't recognize that then it should be implemented
*/

const ctxLog = (...args) => {
    debug("css-change", ...args)
}

// TODO: if we change this file to .ts then it's not working, I do not know why.
export class CSSChangeHandler extends Handler {
    constructor({ utils, eventsStream, hooks, throttleMax, throttleInterval }) {
        super()
        this.utils = utils
        this.hooks = hooks
        this.eventsStream = eventsStream
        this.cssMap = new WeakMap()
        this.nextId = 1

        this.watchedEvents = {}
        this.throttle = new Throttle({
            interval: throttleInterval,
            limit: throttleMax,
            onLimit: () => {
                this.debug("Warning: CSS changes throttled!")
            },
        })
    }

    Register() {}

    Init() {
        let _this = this

        this.watchedEvents.insertRule = this.hooks.Create(window.CSSStyleSheet.prototype, "insertRule", {
            after: function () {
                safe(() => {
                    _this.throttle.Use(() => {
                        let id = _this.utils.DOM.GetNodeID(this.ownerNode)
                        if (id) {
                            _this.insertRule(id, arguments[0], arguments[1])
                        } else {
                            // hack, if DOMChange should fired first
                            setTimeout(() => {
                                id = _this.utils.DOM.GetNodeID(this.ownerNode)
                                _this.insertRule(id, arguments[0], arguments[1])
                            }, 0)
                        }
                    })
                })
            },
        })

        this.watchedEvents.deleteRule = this.hooks.Create(window.CSSStyleSheet.prototype, "deleteRule", {
            after: function () {
                safe(() => {
                    _this.throttle.Use(() => {
                        const id = _this.utils.DOM.GetNodeID(this.ownerNode)
                        _this.deleteRule(id, arguments[0])
                    })
                })
            },
        })


        this.addAdoptedStyleSheetsHooks()

        const snapshot = this.snapshot({ styleOnly: true, contentLessOnly: true })

        this.eventsStream.Add(this.name, { json_data: snapshot })
    }

    Unregister() {
        this.watchedEvents.insertRule && this.hooks.Unbind(this.watchedEvents.insertRule)
        this.watchedEvents.deleteRule && this.hooks.Unbind(this.watchedEvents.deleteRule)
        this.cssMap = new WeakMap()
    }

    Handler(e) {
        this.eventsStream.Add(this.name)
    }

    addAdoptedStyleSheets(id, adoptedStyleSheets) {
        this.addAdoptedStyleSheetsEvent(id, adoptedStyleSheets)
    }

    sheetRules(sheet) {
        try {
            return sheet.cssRules || sheet.rules
        } catch (e) {
            return false
        }
    }

    snapshot({ styleOnly = false, contentLessOnly = false }) {
        let result = []
        for (let i = 0; i < (document.styleSheets || []).length; i++) {
            const sheet = document.styleSheets[i]
            const rules = this.sheetRules(sheet)
            const lsid = this.utils.DOM.GetNodeID(sheet.ownerNode)

            const sheetTextContent = (sheet.ownerNode.textContent || "").trim()

            if (styleOnly && sheet.ownerNode.tagName !== "STYLE") continue
            if (contentLessOnly && sheetTextContent !== "") continue

            if (rules) {
                for (let j = 0; j < rules.length; j++) {
                    let res = ""
                    try {
                        res = sheet.cssRules[j].cssText
                    } catch (e) {}
                    if (res != "") {
                        result.push({ lsid: lsid, i: j, d: res })
                    }
                }
            }
        }
        return { t: TYPE_SNAPSHOT, d: result }
    }

    insertRule(id, rule, index) {
        const data = {
            lsid: id,
            t: TYPE_INSERT,
            i: index,
            d: rule,
        }
        this.eventsStream.Add(this.name, { json_data: data })
    }

    deleteRule(id, index) {
        const data = {
            lsid: id,
            t: TYPE_DELETE,
            i: index,
        }
        this.eventsStream.Add(this.name, { json_data: data })
    }

    addAdoptedStyleSheetsEvent(id, adoptedStyleSheets, index) {
        if (!id) {
            return
        }

        if (!adoptedStyleSheets || !adoptedStyleSheets.length) {
            return
        }

        const sheets = []

        for (let i = 0; i < adoptedStyleSheets.length; i++) {
            const sheet = adoptedStyleSheets[i]
            if (!sheet) {
                ctxLog("sheet not found")
                continue
            }

            let nextId = this.cssMap.get(sheet)

            if (nextId) {
                sheets.push({i: nextId})
                continue
            }

            const rules = this.sheetRules(sheet)
            if (!rules) {
                ctxLog("rules not found")
                continue
            }

            const sheetRules = []

            for (let k = 0; k < rules.length; k++) {
                const txt = rules[k]?.cssText
                if (!txt) {
                    continue
                }

                sheetRules.push({
                    d: txt,
                    i: k
                })
            }

            sheets.push({i: this.nextId, r: sheetRules})
            this.cssMap.set(sheet, this.nextId)
            this.nextId++
        }


        if (!sheets.length) {
            return
        }

        const event = {
            lsid: id,
            t: TYPE_ADOPTED_STYLESHEET,
            i: index,
            d: sheets,
        }

        this.eventsStream.Add(this.name, { json_data: event })
    }

    onAddAdoptedStyleSheetsProto(node, adoptedStyleSheets) {
        const id = this.utils.DOM.GetNodeID(node)
        if (!id) {
            return
        }

        this.addAdoptedStyleSheets(id, adoptedStyleSheets)
    }

    addAdoptedStyleSheetsHooks() {
        const self = this;

        const shadowProto = Object.getOwnPropertyDescriptor(window.ShadowRoot.prototype, 'adoptedStyleSheets');
        const documentProto = Object.getOwnPropertyDescriptor(window.Document.prototype, 'adoptedStyleSheets');

        const objectPropertyAttrs = (descriptor) => ({
            get: function () {
                return descriptor.get.call(this)
            },
            set: function(val) {
                descriptor.set.call(this, val)
                self.onAddAdoptedStyleSheetsProto(this, val)
            },
            enumerable: true,
            configurable: true,
        })

        shadowProto && Object.defineProperty(window.ShadowRoot.prototype, "adoptedStyleSheets", objectPropertyAttrs(shadowProto));
        documentProto && Object.defineProperty(window.Document.prototype, "adoptedStyleSheets", objectPropertyAttrs(documentProto));
    }
}
