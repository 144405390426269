import { Ticker } from "../../common/ticker"
import { safe } from "../../common/tools"
import { DOMHandler } from "./handler"
import { events } from "../events/typedef"
import { IUtils } from "../utils/Utils"
import { IEventsStream } from "../events/stream"
import { IEventsListener } from "../../common/eventListener"

class SelectionHandler extends DOMHandler {
    selectEventLength: number
    utils: IUtils
    eventsStream: IEventsStream
    lastValue: any[]
    lastValueSelected: boolean
    value: any[]
    constructor({
        utils,
        eventsStream,
        globalEvents,
        aquireInterval,
    }: {
        utils: IUtils
        eventsStream: IEventsStream
        globalEvents: IEventsListener
        aquireInterval: number
    }) {
        super()
        this.selectEventLength = 5
        this.utils = utils
        this.eventsStream = eventsStream
        this.lastValue = []
        this.lastValueSelected = false
        this.value = []

        this.setSupportShadow(true)
        this.eventHandler = (e) => safe(this.Handler.bind(this), e)

        globalEvents.on(`handlers.${events.MUTATION}.inited`, (rootID, children, mirror) => {
            this.mirror = mirror
        })
        this.ticker = new Ticker({ interval: aquireInterval })
    }

    Register() {
        this.AddListener()
        this.ticker.Start(this.handleInterval.bind(this))
    }

    Unregister() {
        this.RemoveListener()
        this.ticker.Stop()
    }

    AddListener(element = document) {
        element.addEventListener("selectionstart", this.eventHandler)
        element.addEventListener("selectionchange", this.eventHandler)
    }

    RemoveListener(element = document) {
        element.removeEventListener("selectionstart", this.eventHandler)
        element.removeEventListener("selectionchange", this.eventHandler)
    }

    Handler(e) {
        if (!this.mirror) return null
        this.value = this.selectionArgs()
    }

    handleInterval() {
        if (this.utils.ArrayEqual(this.lastValue, this.value)) {
            return
        }
        this.lastValue = this.value.slice()
        if (this.value.length === this.selectEventLength || this.lastValueSelected) {
            this.eventsStream.Add(this.name, {
                json_data: this.value,
            })
        }
        this.lastValueSelected = this.value.length === this.selectEventLength
    }

    id(el) {
        return this.mirror.knownNodes.get(el)
    }

    l(a, b) {
        if (!a.firstChild) return [a, b]
        a = a.firstChild
        for (var c = 0; c < b - 1 && a.nextSibling; c++) a = a.nextSibling
        return [a, 0]
    }

    selectionArgs(): any[] {
        if (!window.getSelection) return []
        var b = window.getSelection()
        if (!b || "None" == b.type) return []
        if ("Caret" == b.type) {
            var c = this.id(b.anchorNode)
            return c ? [c, b.anchorOffset] : []
        }
        if (!b.anchorNode || !b.focusNode) return []

        var d = this.l(b.anchorNode, b.anchorOffset),
            c = d[0],
            d = d[1],
            b = this.l(b.focusNode, b.focusOffset),
            g = b[0],
            e = b[1],
            g = (b = Boolean(c.compareDocumentPosition(g) & Node.DOCUMENT_POSITION_FOLLOWING)) ? [c, g] : [g, c],
            c = g[0],
            g = g[1],
            e = b ? [d, e] : [e, d],
            d = e[0],
            e = e[1]
        for (this.id(c) || (d = 0); c && !this.id(c) && c != g; ) c = c.nextSibling || c.parentNode
        for (this.id(g) || (e = 0); g && !this.id(g) && g != c; ) g = g.previousSibling || g.parentNode
        if (c == g && d == e) return []
        var h = this.id(c),
            m = this.id(g)
        return c && g && h && m ? [h, d, m, e, b] : []
    }
}

export { SelectionHandler }
