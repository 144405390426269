const events = {
    MUTATION: "DOMChange",
    ELEMENT_BLUR: "ElementBlur",
    ELEMENT_FOCUS: "ElementFocus",
    LOCATION_CHANGE: "LocationChange",
    MOUSE_CLICK: "MouseClick",
    MOUSE_DOUBLE_CLICK: "MouseDoubleClick",
    MOUSE_DOWN: "MouseDown",
    MOUSE_MOVE: "MouseMove",
    TOUCH_MOVE: "TouchMove",
    MOUSE_UP: "MouseUp",
    SCROLL: "Scroll",
    SELECTION: "Selection",
    VALUE_CHANGE: "ValueChange",
    VALUE_SET: "ValueSet",
    FORM_SUBMIT: "FormSubmit",
    WINDOW_BLUR: "WindowBlur",
    WINDOW_FOCUS: "WindowFocus",
    WINDOW_RESIZE: "WindowResize",
    WINDOW_SCROLL: "WindowScroll",
    CSS_CHANGE: "CSSChange",
    RESOURCE_ADDED: "ResourceAdded",
    ERROR_CLICK: "ErrorClick",
    RAGE_CLICK: "RageClick",
    LOG: "Log",
    ERROR: "Error",
    CUSTOM: "Custom",
    PERFORMANCE: "Performance",
    TECHNOLOGY: "Technology",
    NET_LOG: "NetLog",
    NET_ERROR: "NetError",
    IDENTIFY: "Identify",
    ANIMATION: "Animation"
}

const eventsKind = {
    DOMChange: 1,
    ElementBlur: 2,
    ElementFocus: 3,
    LocationChange: 4,
    MouseClick: 5,
    MouseDoubleClick: 6,
    MouseDown: 7,
    MouseMove: 8,
    TouchMove: 9,
    MouseUp: 10,
    Scroll: 11,
    Selection: 12,
    ValueChange: 13,
    WindowBlur: 14,
    WindowFocus: 15,
    WindowResize: 16,
    WindowScroll: 17,
    CSSChange: 18,
    ResourceAdded: 19,
    ErrorClick: 20,
    RageClick: 21,
    FormSubmit: 22,
    ValueSet: 23,
    Log: 24,
    Error: 25,
    Custom: 26,
    Performance: 27,
    Technology: 28,
    NetLog: 29,
    NetError: 30,
    Identify: 31,
    Animation: 32
}

const logLevel = {
    internal: 0,
    log: 1,
    info: 2,
    warn: 3,
    error: 4,
    debug: 5,
}
const consoleLogLevel = {
    log: "log",
    info: "info",
    warn: "warn",
    error: "error",
}

const allowRule = { include: "include", exclude: "exclude" }

const getKindFromEventName = (name) => eventsKind[name]

const defaultConditionEvents = [events.MOUSE_MOVE, events.TOUCH_MOVE, events.SCROLL, events.WINDOW_SCROLL]

export { events, eventsKind, getKindFromEventName, logLevel, consoleLogLevel, defaultConditionEvents, allowRule }
