import {Handler} from "./handler";
import {IHook, IHooks} from "../hooks";
import {IEventsStream} from "../events/stream";
import { debug, safe } from "../../common/tools"

const TYPE_ANIMATION_CREATED = 1
const TYPE_ANIMATION_METHOD_CALLED = 2
const TYPE_KEYFRAME_EFFECT_CREATED = 3

const ctxLog = (...args) => {
    debug("animations", ...args)
}

/*
    TODO:
        * animation cache
        * eventListeners cleanup - does garbage collection clean up if reference (LsAnimation) is not used?
        * check support for animation name (https://developer.mozilla.org/en-US/docs/Web/CSS/animation-name)
        * support AnimationTimeline other than DocumentTimeline (https://developer.mozilla.org/en-US/docs/Web/API/Animation/Animation)
        * animations can be automatically removed ((https://developer.mozilla.org/en-US/docs/Web/API/Animation#automatically_removing_filling_animations)
        * add type for CSSAnimation or CSSTransition
        * browser tests
 */

export class AnimationsHandler extends Handler {
    private nextId = 1;
    private currentHook?: IHook
    // TODO: is this right concept? does browser automatically cleanup animation if we keep reference to it?
    private animationsMap = new WeakMap()

    constructor(
        private hooks: IHooks,
        private utils: any,
        private eventStream: IEventsStream
    ) {
        super();

        this.processAnimation = this.processAnimation.bind(this)
        this.animationMethodCalledEvent = this.animationMethodCalledEvent.bind(this)
    }

    public Register(): void {
        this.addAnimationHook()
    }

    public Unregister(): void {
        this.removeAnimationHook()
        this.nextId = 0
        this.animationsMap = new WeakMap()
    }

    private addAnimationHook() {
        const [proto, fnName] = [window.Element.prototype, "animate"]

        if (!this.hooks.CanBind(proto, fnName)) {
            return
        }

        const self = this

        this.currentHook = this.hooks.Create(proto, fnName, {
            afterAsync: function () {
                safe(() => {
                    const node: Element = this

                    self.onNodeAnimate(node)
                })
            }
        })
    }

    private removeAnimationHook() {
        if (this.currentHook) {
            this.currentHook.Unbind()
        }
    }

    public onNodeAnimate(node: Element): void {
        const animations = node.getAnimations()

        animations.forEach(ev => this.processAnimation(node, ev))
    }

    private processAnimation(node: Element, anim: Animation) {
        const nodeId = this.utils.DOM.GetNodeID(node) as string
        if (!nodeId) {
            return
        }

        const rememberedAnimation = this.animationsMap.has(anim)
        let animId: number = 0;

        if (!rememberedAnimation) {
            animId = this.nextId
            this.animationCreatedEvent(nodeId, this.nextId)
            this.animationsMap.set(anim, this.nextId)
            this.nextId++
        } else {
            animId = this.animationsMap.get(anim)
        }

        const keyFrameEffect = LsAnimation.getKeyFrameEffect(anim)
        const rememberedKeyFrame = keyFrameEffect && this.animationsMap.has(keyFrameEffect)

        if (keyFrameEffect && !rememberedKeyFrame) {
            this.keyFrameEffectCreatedEvent(nodeId, keyFrameEffect, animId, this.nextId)
            this.animationsMap.set(keyFrameEffect, this.nextId)
            this.nextId++
        }

        switch (anim.playState) {
            case "finished": {
                this.animationMethodCalledEvent(nodeId, animId, "finish")
                break
            }
            case "paused": {
                this.animationMethodCalledEvent(nodeId, animId, "pause")
                break
            }
            case "running": {
                this.animationMethodCalledEvent(nodeId, animId, "play")
                break
            }
        }

        if (rememberedAnimation) {
            return
        }

        const lsAnim = new LsAnimation(
            anim,
            animId,
            this.utils,
            this.animationMethodCalledEvent,
        )

        lsAnim.addEventListeners()
    }

    private animationCreatedEvent(nodeId: string, animId: number) {
        const data = {
            lsid: nodeId,
            t: TYPE_ANIMATION_CREATED,
            i: animId,
        }

        this.eventStream.Add(this.name, {json_data: data})
    }

    private animationMethodCalledEvent(
        nodeId: string,
        animId: number,
        method: "play" | "finish" | "pause" | "cancel"
    ) {
        const data = {
            lsid: nodeId,
            t: TYPE_ANIMATION_METHOD_CALLED,
            ai: animId,
            m: method,
        }

        this.eventStream.Add(this.name, {json_data: data})
    }

    private keyFrameEffectCreatedEvent(
        nodeId: string,
        keyFrameEffect: KeyframeEffect,
        animId: number,
        keyFrameId: number,
    ) {
        const keyframeData = LsFrameEffect.create(keyFrameEffect)

        const data = {
            lsid: nodeId,
            t: TYPE_KEYFRAME_EFFECT_CREATED,
            d: keyframeData,
            i: keyFrameId,
            ai: animId,
        }

        this.eventStream.Add(this.name, {json_data: data})
    }
}

export class LsAnimation {
    constructor(
        private anim: Animation,
        public readonly id: number,
        private utils: any,
        private onListener: (
            nodeId: string,
            animId: number,
            method: "play" | "finish" | "pause" | "cancel"
        ) => void
    ) {
        this.onFinish = this.onFinish.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.onRemove = this.onRemove.bind(this)
    }

    public addEventListeners() {
        this.anim.addEventListener("finish", this.onFinish)
        this.anim.addEventListener("cancel", this.onCancel)
        this.anim.addEventListener("remove", this.onRemove)
    }

    public static getKeyFrameEffect(anim: Animation): KeyframeEffect | null {
        return anim.effect ?
            anim.effect instanceof KeyframeEffect ?
                anim.effect :
                null :
            null
    }

    private removeEventListeners() {
        this.anim.removeEventListener("finish", this.onFinish)
        this.anim.removeEventListener("cancel", this.onCancel)
        this.anim.removeEventListener("remove", this.onRemove)
    }

    private onFinish(ev: AnimationPlaybackEvent): void {
        const nodeId = this.getNodeIdFromEvent(ev)

        if (!nodeId || !this.onListener) {
            return
        }

        this.onListener(nodeId, this.id, "finish")
    }

    private onCancel(ev: AnimationPlaybackEvent): void {
        const nodeId = this.getNodeIdFromEvent(ev)

        if (!nodeId || !this.onListener) {
            return
        }

        this.onListener(nodeId, this.id, "cancel")
    }

    private onRemove(ev: Event): void {
        const nodeId = this.getNodeIdFromEvent(ev as AnimationPlaybackEvent)

        if (!nodeId || !this.onListener) {
            return
        }

        this.removeEventListeners()

        // this.onListener(nodeId, "remove") // TODO: support remove called?
    }

    private getNodeIdFromEvent(ev: AnimationPlaybackEvent): string | void {
        const anim = ev.target as Animation
        if (!anim) {
            return
        }

        const keyframe = anim.effect as KeyframeEffect

        if (!keyframe) {
            return
        }

        const node = keyframe.target

        if (!node) {
            return
        }

        const id = this.utils.DOM.GetNodeID(node)

        if (!id) {
            ctxLog("node id not found from event")
            return
        }

        return id
    }
}

type LsKeyFramePropValue = string | number | null | undefined

type LsKeyFrame = {
    composite: CompositeOperationOrAuto
    easing: string
    offset: number | null;
    [property: string]: LsKeyFramePropValue;
}

class LsFrameEffect {
    constructor(
        public readonly frames: LsKeyFrame[],
        public readonly timing: EffectTiming,
        public readonly iterationComposite: IterationCompositeOperation,
        public readonly composite: CompositeOperation,
    ) {
    }

    public static create(effect: KeyframeEffect): LsFrameEffect {
        const frames = effect.getKeyframes()
        const timing = effect.getTiming()

        const framesData = frames.map(frame => {
            const props: LsKeyFrame = {} as LsKeyFrame

            for (const key in frame) {
                if (["computedOffset"].includes(key)) {
                    break
                }

                const value = frame[key]

                props[key] = value
            }

            return props
        })

        const timingData = {
            delay: timing.delay,
            direction: timing.direction,
            duration: timing.duration,
            easing: timing.easing,
            endDelay: timing.endDelay,
            fill: timing.fill,
            iterationStart: timing.iterationStart,
            iterations: timing.iterations,
        }

        return {
            frames: framesData,
            timing: timingData,
            iterationComposite: effect.iterationComposite,
            composite: effect.composite,
        }
    }
}
