import { isDOMElement } from "../../helpers/objectHelper"
class DOM {
    constructor() {
        this.recordingElementsMap = null
    }

    SetRecordingElementsMap(recordingElementsMap) {
        this.recordingElementsMap = recordingElementsMap
    }

    ToJSON(node) {
        node = node || this
        let obj = {
            nodeType: node.nodeType,
        }
        if (node.tagName) {
            obj.tagName = node.tagName.toLowerCase()
        } else if (node.nodeName) {
            obj.nodeName = node.nodeName
        }
        if (node.nodeValue) {
            obj.nodeValue = node.nodeValue
        }
        let attrs = node.attributes
        if (attrs) {
            let length = attrs.length
            let arr = (obj.attributes = new Array(length))
            for (let i = 0; i < length; i++) {
                let attr = attrs[i]
                arr[i] = [attr.nodeName, attr.nodeValue]
            }
        }
        let childNodes = node.childNodes
        if (childNodes) {
            length = childNodes.length
            arr = obj.childNodes = new Array(length)
            for (i = 0; i < length; i++) {
                arr[i] = toJSON(childNodes[i])
            }
        }
        return obj
    }

    ToDOM(obj) {
        if (typeof obj == "string") {
            obj = JSON.parse(obj)
        }
        let node,
            nodeType = obj.nodeType
        switch (nodeType) {
            case 1: //ELEMENT_NODE
                node = document.createElement(obj.tagName)
                let attributes = obj.attributes || []
                for (let i = 0, len = attributes.length; i < len; i++) {
                    let attr = attributes[i]
                    node.setAttribute(attr[0], attr[1])
                }
                break
            case 3: //TEXT_NODE
                node = document.createTextNode(obj.nodeValue)
                break
            case 8: //COMMENT_NODE
                node = document.createComment(obj.nodeValue)
                break
            case 9: //DOCUMENT_NODE
                node = document.implementation.createDocument()
                break
            case 10: //DOCUMENT_TYPE_NODE
                node = document.implementation.createDocumentType(obj.nodeName)
                break
            case 11: //DOCUMENT_FRAGMENT_NODE
                node = document.createDocumentFragment()
                break
            default:
                return node
        }
        if (nodeType == 1 || nodeType == 11) {
            let childNodes = obj.childNodes || []
            for (i = 0, len = childNodes.length; i < len; i++) {
                node.appendChild(toDOM(childNodes[i]))
            }
        }
        return node
    }

    Name(el) {
        let name = el?.tagName ? el?.tagName?.toLowerCase() : ""
        if (el.id != "") {
            return "#" + el.id
        }
        if (typeof el?.className?.split === "function") {
            // for omitting svg
            let classes = el?.className?.split(" ")
            return name + classes?.join(".")
        }

        return name
    }

    pathName(el) {
        if (el.id) {
            return "#" + el.id
        }

        if (el == el?.ownerDocument?.documentElement) {
            return el.tagName
        }

        return this.ElementName(el)
    }

    fullPath(el, next) {
        if (!el) {
            return ""
        }

        let names = []
        let i = 1;

        names.push(this.pathName(el))

        while(!el.id && (el = next(el, i))) {
            if (el.id) {
                break
            }
            i++
            const path = this.pathName(el)

            if (path) {
                names.unshift(path)
            }
        }

        return names.join(" > ") || ""
    }

    FullPath(el) {
        if (!el) {
            return ""
        }

        return this.fullPath(el, (el) => {
            return el.parentNode
        })
    }

    FullPathV2(e) {
        if (e.composed && e.target && e.target.shadowRoot) {
            const elements = e.composedPath()

            return this.fullPath(elements[0], (_, i) => {
                return elements[i]
            })
        }

        if (e && e.target) {
            return this.FullPath(e.target)
        }

        return ""
    }

    ElementName(el) {
        if (!el || !isDOMElement(el)) return
        if (el.id) {
            return "#" + el.id
        } else {
            if (el == el?.ownerDocument?.documentElement) return el.tagName
            else {
                let elementName = el.tagName?.toLowerCase()
                if (typeof el.className?.split === "function") {
                    // for omitting svg
                    if (el.className && el.className?.trim() != "") {
                        elementName +=
                            "." +
                            el.className
                                ?.trim()
                                ?.split(" ")
                                ?.filter((n) => !!n)
                                ?.join(".")
                    }
                }
                const attrs = ["data-ls-name", "href", "name", "type"]
                for (let i = 0, l = attrs.length; i < l; i++) {
                    const attrName = attrs[i]
                    const attr = el.getAttribute(attrName)
                    if (attr && attr != "") {
                        return elementName + `[${attrName}="${attr}"]`
                    }
                }
                return elementName
            }
        }
    }

    recordingElementExcluded(el) {
        const { exclude } = this.recordingElementsMap || {}

        if (!exclude) {
            return
        }

        for (let selector in exclude) {
            if (el && el.matches && el.matches(selector)) {
                return true
            }
        }
    }

    recordingElementIncluded(el) {
        const { include } = this.recordingElementsMap || {}

        if (!include) {
            return
        }

        for (let selector in include) {
            if (el && el.matches && el.matches(selector)) {
                return true
            }
        }
    }

    recordingElementMasked(el) {
        const { mask } = this.recordingElementsMap || {}

        if (!mask) {
            return
        }

        for (let selector in mask) {
            if (el && el.matches && el.matches(selector)) {
                return true
            }
        }
    }

    isElementContentDisabled(el) {
        if (!el) {
            return false
        }

        const self = this

        function isDisabled(e) {
            if (e.getAttribute && e.getAttribute("data-ls-enabled") !== null) {
                return false
            }
            if (e.getAttribute && e.getAttribute("data-ls-disabled") !== null) {
                return true
            }

            if (self.recordingElementIncluded(e)) {
                return false
            }
            if (self.recordingElementExcluded(e)) {
                return true
            }

            return null
        }

        let disabled = isDisabled(el)

        if (typeof disabled === "boolean") {
            return disabled
        }

        while (el.parentNode) {
            el = el.parentNode
            disabled = this.isElementContentDisabled(el)

            if (typeof disabled === "boolean") {
                return disabled
            }
        }

        return false
    }

    shouldMaskIMG(node) {
        if (this.recordingElementMasked(node)) {
            return true
        }

        return node.getAttribute("data-ls-mask") !== null
    }

    maskIMG(nodeData, node, onLoadCb) {
        nodeData.a["src"] = null
        nodeData.a["data-ls-img"] = this.disableImage(node, onLoadCb)
    }

    disableImage(node, onLoadCb) {
        if (node.complete) {
            return { w: node.width, h: node.height }
        } else {
            node.onload = function () {
                onLoadCb &&
                    onLoadCb({
                        i: this._lsid,
                        a: { "data-ls-img": { w: this.width, h: this.height } },
                    })
            }
        }
        return null
    }

    GetTextContent(obj, txt = "", max = 128) {
        let childNodes = obj.childNodes || []
        for (let i = 0, len = childNodes.length; i < len; i++) {
            const el = childNodes[i]
            if (!this.isElementContentDisabled(el)) {
                if (el.nodeType == 3) {
                    txt += " " + el.textContent
                    if (txt.length > max) {
                        return txt
                    }
                }
            }
            if (el.childNodes) txt = this.GetTextContent(el, txt)
        }
        return txt
    }

    Content(el) {
        if (!el) {
            return ""
        }
        if (this.isElementContentDisabled(el)) {
            return ""
        }
        let txt = this.GetTextContent(el)
        if (el.tagName === "INPUT") {
            if (el.parentElement) {
                const label = el.parentElement.querySelector(`label[for='${el.getAttribute("name")}']`)
                if (label && !this.isElementContentDisabled(label)) {
                    txt = this.GetTextContent(label)
                } else if (el.parentElement.tagName === "LABEL") {
                    txt = this.GetTextContent(el.parentElement)
                }
            }
        }
        txt = txt?.replace(/\r?\n|\r/g, " ")?.trim()

        if (txt && txt.length > 64) {
            return txt.substr(0, 59) + "[...]"
        }
        return txt
    }

    GetNodeID(node) {
        return (node && node._lsid) || null
    }

    IsNode(o) {
        return typeof Node === "object" ? o instanceof Node : o && typeof o === "object" && typeof o.nodeType === "number" && typeof o.nodeName === "string"
    }
}

export { DOM }
