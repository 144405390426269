import forEach from "for-each"

class Integrations {
    constructor({ globalEvents }) {
        this.enabled = {}
        this.list = {}
        this.globalEvents = globalEvents
    }

    Register(name, handler) {
        this.list[name] = handler
    }

    SetEnabledList(enabled) {
        this.enabled = enabled || {}
        forEach(this.list, (integration, name) => {
            const cfg = this.enabled[name]
            if (cfg && cfg.enabled) {
                integration.Enable()
                integration.setProps(cfg.properties)
            }
        })
    }

    Scan() {
        forEach(this.list, (integration, name) => {
            if (this.enabled[name]) integration.scan()
        })
    }
}

export { Integrations }
