import { Base } from "./base"

class LiveChat extends Base {
    constructor({ utils, maxAttemps, globalEvents, xhrTransport, visitor, options, JSAPI }) {
        super({ globalEvents, name: "LiveChat" })
        this.utils = utils
        this.maxAttemps = maxAttemps
        this.xhrTransport = xhrTransport
        this.visitor = visitor
        this.options = options
        this.JSAPI = JSAPI

        this.inited = false
        this.propsSended = false
        this.isLivechatReady = false
        this.properties = {}
    }

    onLivechatReady() {
        const accountID = this.options.AccountID()
        const visitorID = this.visitor.ID()
        const sessionID = this.visitor.SessionID()

        //TODO: if visitorID/sessionID is empty because script failed

        if (!visitorID || !sessionID) {
            console.error("(livechat-integration): visitorID and sessionID cannot be empty")
            return
        }

        this.isLivechatReady = true

        const livesessionData = `${accountID}.${visitorID}.${sessionID}`

        window.LiveChatWidget.call("set_session_variables", {
            livesessionData: btoa(livesessionData),
        })
    }

    check() {
        if (this.isLivechatReady) {
            return true
        }

        const { LiveChatWidget } = window

        const liveChatIsReady = typeof LiveChatWidget === "object" && LiveChatWidget.on
        if (liveChatIsReady) {
            LiveChatWidget.on("ready", this.onLivechatReady.bind(this))
        }

        return this.isLivechatReady
    }
}

export { LiveChat }
