export function toObject(input: string): object {
    return input.split(/[\r\n]+/).reduce((e, t) => {
        const n = e
        const r = t.split(": ")
        if (r.length > 1) {
            const o = r.shift()
            const i = r.join(": ")
            e[o] ? (n[o] += ", " + i) : (n[o] = i)
        }
        return n
    }, {})
}

export function bytesSize(str: string): number {
    // return encodeURI(str).split(/%..|./).length - 1;
    return new Blob([str]).size
}

export function endsWith(str: string, suffix: string): boolean {
    return str.indexOf(suffix, str.length - suffix.length) !== -1
}
