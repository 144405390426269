export class Backoff {
    constructor({ backOffIntervalMs, backOffMaxMs, maxTicks } = {}) {
        this.backOffMaxMs = backOffMaxMs || 3000
        this.backOffIntervalMs = backOffIntervalMs || 500
        this.maxTicks = maxTicks || 10
    }

    start(conditionCb, backoffCb) {
        if (typeof conditionCb != "function" || typeof backoffCb != "function") {
            return
        }

        if (conditionCb()) {
            backoffCb()
            return
        }

        const start = +Date.now()

        let i = 0

        const tick = setInterval(() => {
            i++
            if (i >= this.maxTicks) {
                clearInterval(tick)
                backoffCb()
                return
            }
            const now = +Date.now()
            const delta = now - start

            const condition = conditionCb()

            if (condition || delta >= this.backOffMaxMs) {
                clearInterval(tick)
                backoffCb()
            }
        }, this.backOffIntervalMs)
    }
}
