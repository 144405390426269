export interface IHook {
    Unbind(): void
}

class Hook implements  IHook {
    constructor(proto, fn, opts = {}) {
        this.native = proto[fn]
        this.proto = proto
        this.fn = fn

        if (opts.after) this.After(opts.after)
        if (opts.afterAsync) this.AfterAsync(opts.afterAsync)
        if (opts.before) this.Before(opts.before)

        this.Bind()
    }

    Bind() {
        const _this = this
        this.proto[this.fn] = function () {
            if (_this.before) _this.before.apply(this, arguments)

            let result = _this.native.apply(this, arguments)

            if (_this.after) {
                _this.after.apply(this, arguments)
            }
            if (_this.afterAsync) setTimeout(() => _this.afterAsync.apply(this, arguments), 0)

            return result
        }
    }

    Unbind() {
        this.proto[this.fn] = this.native
    }

    After(fn) {
        this.after = fn
    }
    AfterAsync(fn) {
        this.afterAsync = fn
    }
    Before(fn) {
        this.before = fn
    }
}

interface CreateOpts {
    after?(): void
    afterAsync?(): void
    before?(): void
}

interface IHooks {
    CanBind(name: any, obj: any, time?: number): boolean
    Create(proto: any, fn: string, opts: CreateOpts): IHook
    Unbind(hook: typeof Hook): void
}

class Hooks implements IHooks {
    hooks: any
    constructor() {
        this.hooks = {}
    }

    CanBind(proto, fn) {
        return !!proto && !!proto[fn]
    }

    Create(proto, fn, opts) {
        if (this.hooks[fn]) {
            return this.hooks[fn]
        }

        let hook = new Hook(proto, fn, opts)
        this.hooks[fn] = hook
        return hook
    }

    Unbind(hook) {
        hook.Unbind()
        this.hooks[hook.fn] = null
    }
}

export { Hook, Hooks, IHooks }
