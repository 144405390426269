import { Ticker } from "../../common/ticker"
import { safe } from "../../common/tools"
import { DOMHandler } from "./handler"
import { events } from "../events/typedef"

class ScrollHandler extends DOMHandler {
    constructor({ utils, eventsStream, aquireInterval, globalEvents }) {
        super()
        this.startTime = null
        this.lastValue = { el: null, x: 0, y: 0, lsid: null }
        this.value = { el: null, x: 0, y: 0, json_data: {} }
        this.intervalID = 0

        this.setSupportShadow(true)
        this.eventHandler = (e) => safe(this.Handler.bind(this), e)

        this.utils = utils
        this.eventsStream = eventsStream
        this.ticker = new Ticker({ interval: aquireInterval })

        globalEvents.on(`handlers.${events.MUTATION}.inited`, (rootID, children, mirror) => {
            this.mirror = mirror
        })
    }

    Register() {
        this.startTime = null
        this.Handler()
        this.handleInterval()

        this.AddListener()
        this.ticker.Start(this.handleInterval.bind(this))
    }

    Unregister() {
        this.startTime = null
        this.RemoveListener()
        this.ticker.Stop()
    }

    AddListener(element = document) {
        element.addEventListener("scroll", this.eventHandler, true)
    }

    RemoveListener(element = document) {
        element.removeEventListener("scroll", this.eventHandler, true)
    }

    isValueChanged() {
        const { x, y, el, lsid } = this.lastValue

        return x != this.value.x || y != this.value.y || el != this.value.el || this.value.json_data.lsid != lsid
    }

    handleInterval() {
        if (!this.mirror || !this.isValueChanged()) {
            this.startTime = null
            return
        }

        this.lastValue = { el: this.value.el, x: this.value.x, y: this.value.y, lsid: this.value.json_data.lsid }

        this.eventsStream.Add(this.name, this.value, this.startTime)
        this.startTime = null
    }

    Handler(e) {
        if (
            !e || !e.target ||
            e.target.scrollLeft === null || e.target.scrollTop === null ||
            isNaN(e.target.scrollLeft) || isNaN(e.target.scrollTop) ||
            !this.mirror
        ) {
            return
        }

        if (!this.startTime) {
            this.startTime = this.utils.Time.Duration()
        }

        this.value = {
            x: e.target.scrollLeft,
            y: e.target.scrollTop,
            path: this.utils.DOM.FullPath(e.target),
            el: this.utils.DOM.ElementName(e.target),
            json_data: { lsid: this.mirror.knownNodes.get(e.target) }
        }
    }
}

export { ScrollHandler }
