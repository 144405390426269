import { events } from "./events/typedef"

class FormWatcher {
    constructor({ utils, eventsStream, options, globalEvents }) {
        this.utils = utils
        this.options = options
        this.eventsStream = eventsStream

        this.forms = {}

        globalEvents.on(`handlers.${events.MUTATION}.inited`, (rootID, children, mirror) => {
            this.mirror = mirror
        })
    }

    unregisterForm(node, id) {
        if (this.forms[id]) delete this.forms[id]
        if (node) {
            node.removeEventListener("submit", this.Handler.bind(this), true)
            node._lsWatched = false
        }
    }

    registerForm(node, id) {
        this.forms[id] = node
        if (node._lsWatched) return
        node.addEventListener("submit", this.Handler.bind(this), true)
        node._lsWatched = true
    }

    watchFormNode(node) {
        const lsID = this.mirror.knownNodes.get(node)
        if (lsID) this.registerForm(node, lsID)
    }

    Handler(e) {
        const lsID = this.mirror.knownNodes.get(e.target)
        this.eventsStream.Add(events.FORM_SUBMIT, {
            path: this.utils.DOM.FullPath(e.target),
            el: this.utils.DOM.ElementName(e.target),
            json_data: { lsid: lsID },
        })
    }
}

export { FormWatcher }
