import platform from "platform"
import { DOM } from "./dom"
import { Time } from "./time"
import { getQueryParam } from "../../common/tools"
import { EventSeq } from "./eventSeq"

interface IUTMParams {
    source: string
    medium: string
    campaign: string
    term: string
    content: string
}
interface ISystemInfo {
    resolution: { width: number | undefined; height: number | undefined }
    browser: { name: string | undefined; version: string | undefined; description: string | undefined }
    os: { name: string | undefined; version: string | undefined }
    product: string | undefined
    device: string | undefined
}
interface IUtils {
    StarifyString(text: string): string
    UUID(): string
    ArrayEqual(a: [], b: []): boolean
    getSystemInfo(): ISystemInfo
    getWindowSize(): [number, number]
    findFavIconHref(): string | null
    getUTMParams(): IUTMParams
    getBaseURL(): string
    getCurrentURL(): string
    getDeviceType(): string
    EventSeq: EventSeq
}

class Utils implements IUtils {
    constructor() {
        this.Time = new Time()
        this.EventSeq = new EventSeq()
        this.DOM = new DOM()
        this.platform = platform
    }

    StarifyString(text: string): string {
        if (!text) return text
        let res = ""
        for (let i = 0; i < text.length; i++) {
            let char = "*"
            if (text[i] === " ") {
                char = " "
            }
            res += char
        }
        return res
    }

    UUID(): string {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == "x" ? r : (r & 0x3) | 0x8
            return v.toString(16)
        })
    }

    ArrayEqual(a: [], b: []): boolean {
        if (a === b) return true
        if (a == null || b == null) return false
        if (a.length != b.length) return false

        for (let i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false
        }
        return true
    }

    getSystemInfo(): ISystemInfo {
        return {
            resolution: {
                width: window.screen.width,
                height: window.screen.height,
            },
            browser: {
                name: platform.name,
                version: platform?.version,
                description: platform?.description,
            },
            os: {
                name: platform?.os?.family,
                version: platform?.os?.version,
            },
            product: platform?.product,
            device: this.getDeviceType(),
        }
    }

    getWindowSize(): [number, number] {
        const w = Math.max(window.innerWidth || 0)
        const h = Math.max(window.innerHeight || 0)

        return [w, h]
    }

    findFavIconHref(): string | null {
        const link = document.querySelector("link[rel=icon]")
        if (link) {
            return link.getAttribute("href")
        }
        return ""
    }

    getUTMParams(): IUTMParams {
        return {
            source: getQueryParam("utm_source") || "",
            medium: getQueryParam("utm_medium") || "",
            campaign: getQueryParam("utm_campaign") || "",
            term: getQueryParam("utm_term") || "",
            content: getQueryParam("utm_content") || "",
        }
    }

    getBaseURL() {
        const tag = document.querySelector("base")
        if (tag) {
            const baseURL = tag.getAttribute("href")
            if (baseURL && baseURL != "") {
                return baseURL
            }
        }
        const regexp = new RegExp(/^.*\//)
        return regexp.exec(window.location.href)[0]
    }

    getCurrentURL() {
        return document && document.location && document.location.href
    }

    getDeviceType(): string {
        const mobile =
            /(?:phone|windows\s+phone|ipod|blackberry|(?:android|bb\d+|meego|silk|googlebot) .+? mobile|palm|windows\s+ce|opera\ mini|avantgo|mobilesafari|docomo)/gi
        const tablet = /(?:ipad|playbook|(?:android|bb\d+|meego|silk)(?! .+? mobile))/gi

        if (mobile.test(navigator.userAgent)) return "Mobile"
        if (tablet.test(navigator.userAgent)) return "Tablet"
        return "Desktop"
    }
}

export { Utils, IUtils }
