import Cookie from "js-cookie"
import { Recorder } from "./recorder"
import { getQueryParam, debug, safe } from "./../common/tools"

const getNamespace = () => window.__ls_namespace || "__ls"

export default function (window, document) {
    const init = () => safe(initSafe)
    const initSafe = () => {
        if (typeof window["__ls_inited"] !== "undefined") {
            debug("API", "Already inited.")
            return true
        }

        // omit bots
        if (/bot|googlebot|crawler|spider|robot|crawling|netlify|PingdomTMS/i.test(navigator.userAgent)) {
            return true
        }

        window["__ls_inited"] = 1
        let __ls = window["__ls"] || []

        const isDebug = Cookie.get("__ls_debug") == 1 || getQueryParam("__ls_debug")
        const isDebugForce = Cookie.get("__ls_debug_force") == 1 || getQueryParam("__ls_debug_force")

        if (isDebug) {
            window["__ls_debug"] = isDebug
        }

        if (isDebugForce) {
            window["__ls_debug_force"] = isDebugForce
        }
        const isDisabled = getQueryParam("__ls_optout")
        if (Cookie.get("__ls_optout") || isDisabled) {
            Cookie.set("__ls_optout", "1", { expires: Number.MAX_SAFE_INTEGER })
            debug("API", "Recording disabled.")
            return true
        }
        if (getQueryParam("__ls_off")) {
            debug("API", "Recording disabled.")
            return true
        }
        const showScriptValidationMessage = !!getQueryParam("__ls_validation")

        window.__lsRecorder = new Recorder({ showScriptValidationMessage })

        // backwords compatibility
        const store = window[getNamespace()] && (window[getNamespace()].store || window.__lsStore)

        window[getNamespace()] = function () {
            if (!arguments) return

            const args = [...arguments]
            const fn = window.__lsRecorder.JSAPI[args[0]]
            if (!fn) {
                console.warn("[LS] Undefined function call: " + args[0])
                return
            }
            return safe(() => {
                fn.apply(window.__lsRecorder, args.splice(1))
            })
        }

        if (store) {
            for (let i = 0, l = store.length; i < l; i++) {
                window[getNamespace()](...store[i])
            }
            window.__lsStore = window[getNamespace()].store = undefined
        }
    }

    setTimeout(init, 500)
    document.readyState != "complete" ? (window.attachEvent ? window.attachEvent("onload", init) : window.addEventListener("load", init, false)) : init()
}
