import forEach from "for-each"
import { debug, safe } from "./tools"
import { IAccountSettings } from "./../recorder/account"

const ctxLog = (...args) => {
    debug("EventListener", ...args)
}

interface IEventsListener {
    call(event: string, fn: () => void, id: string): void
    once(event: string, fn: ({ settings }: { settings: IAccountSettings }) => void): void
    flush(): void
    on(event: string, fn: () => string, id?: string): void
}

class EventsListener implements IEventsListener {
    private onceEvents: {}
    private events: {}
    private utils: IUtils
    constructor({ utils }) {
        this.utils = utils
        this.flush()
    }

    flush() {
        ctxLog("flush")
        this.onceEvents = {}
        this.events = {}
    }

    on(event, fn, id) {
        ctxLog("on:", event)
        this.events[event] = this.events[event] || {}

        if (!id) id = this.utils.UUID()
        this.events[event][id] = fn
        return id
    }

    once(event, fn, id) {
        ctxLog("once:", event)
        this.onceEvents[event] = this.onceEvents[event] || {}

        if (!id) id = this.utils.UUID()
        this.onceEvents[event][id] = fn
        return id
    }

    call(event) {
        ctxLog("call:", event)
        let args = [].splice.call(arguments, 1)
        if (this.events[event]) {
            forEach(this.events[event], (fn) => {
                safe(() => fn(...args))
            })
        }
        if (this.onceEvents[event]) {
            forEach(this.onceEvents[event], (fn) => {
                safe(() => fn(...args))
            })
            this.onceEvents[event] = {}
        }
    }
}


export { EventsListener, IEventsListener }
