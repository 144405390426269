import { safe, debug } from "../common/tools"
import { IOptions } from "./Options"
import { IUtils } from "./utils/Utils"
import { INetworkAllowList } from "./handlers/network"

const ctxLog = (...args) => {
    debug("Account", ...args)
}

interface IAccountSettings {
    consoleLogs: boolean
    networkLogs: boolean
    minTimeOnPage: number
    recordingElementsMap: any
    networkAllowList: INetworkAllowList[]
    debugForce: boolean
}

class Account {
    settings: IAccountSettings
    options: IOptions
    utils: IUtils
    constructor({ utils, options }: { utils: IUtils; options: IOptions }) {
        this.utils = utils
        this.options = options
        this.settings = {
            consoleLogs: false,
            networkLogs: false,
            networkAllowList: [],
            minTimeOnPage: 0,
            recordingElementsMap: null,
            debugForce: false,
        }
    }

    Set(settings: IAccountSettings) {
        this.settings = {
            consoleLogs: settings.consoleLogs || false,
            networkLogs: settings.networkLogs || false,
            minTimeOnPage: settings.minTimeOnPage || 0,
            networkAllowList: settings.networkAllowList || [],
            recordingElementsMap: settings.recordingElementsMap || null,
            debugForce: settings.debugForce || false,
        }

        if (settings && settings.recordingElements && settings.recordingElements.list) {
            this.settings.recordingElementsMap = settings.recordingElements.list.reduce((acc, next) => {
                if (!next.enabled) {
                    return {
                        ...acc,
                    }
                }
                if (!acc[next.match_type]) {
                    acc[next.match_type] = {}
                }

                acc[next.match_type][next.element] = true

                return {
                    ...acc,
                }
            }, {})
        }
    }

    Settings() {
        return this.settings
    }
}

export { Account, IAccountSettings }
