"use strict"

// configurables
let maxDepth
let maxString
let replace

const flatTypes = [String, Number, Boolean]

const isDefined = (val) => val !== null && val !== undefined

const isFlat = (val) => !isDefined(val) || ~flatTypes.indexOf(val.constructor)

/**
 * Truncates variables.
 * @param {Object} obj - The object to truncate.
 * @param {Object|Number} [options={}] - If a number, the maxDepth, otherwise configurable options.
 * @param {Number} [options.maxDepth=10] - The max depth to build.
 * @param {Number} [options.maxStrin=256] - The max string length.
 * @param {Object} [options.replace=] - What to replace the truncated reference to.
 * @param {Number} [curDepth=0] - The current depth (used for recursive requests).
 * @returns {Object} The truncated object.
 */
const truncate = (obj, options = {}, curDepth = 0) => {
    options = isNaN(options) ? options : { maxDepth: options }
    options.maxDepth = options.maxDepth || maxDepth
    options.replace = options.replace || replace

    if (curDepth < options.maxDepth) {
        const newDepth = curDepth + 1

        if (isFlat(obj)) {
            return obj
        } else if (Array.isArray(obj)) {
            const newArr = []
            if (obj.length > options.maxKeys / Math.pow(2, curDepth)) {
                obj = obj.slice(0, options.maxKeys / Math.pow(2, curDepth))
                obj.push(options.replace)
            }
            obj.map((value) => {
                if (isFlat(value)) {
                    if (typeof value != "undefined" && value.constructor == String && value.length > options.maxString) {
                        newArr.push(value.substr(0, options.maxString) + "[...]")
                    } else {
                        newArr.push(value)
                    }
                } else {
                    newArr.push(truncate(value, options, newDepth))
                }
            })
            return newArr
        } else {
            const newObj = {}
            let keys = 0
            for (const key in obj) {
                if (keys < options.maxKeys / Math.pow(2, curDepth)) {
                    try {
                        keys++
                        if (isFlat(obj[key])) {
                            if (typeof obj[key] != "undefined" && obj[key].constructor == String && obj[key].length > options.maxString) {
                                newObj[key] = obj[key].substr(0, options.maxString) + "[...]"
                            } else {
                                newObj[key] = obj[key]
                            }
                        } else {
                            newObj[key] = truncate(obj[key], options, newDepth)
                        }
                    } catch (e) {}
                } else {
                    newObj.__lsMaxKeys__ = true
                }
            }
            return newObj
        }
    }

    return options.replace
}

/**
 * Configures globals and defaults.
 * @param {Object} [obj={}] - The configuration.
 * @param {Number} obj.maxDepth - The default and global maxDepth for future truncations.
 * @param {} obj.replace - The default and global replacement value.
 */
truncate.config = (obj = {}) => {
    maxDepth = obj.maxDepth || maxDepth
    maxString = obj.maxString || maxString
    replace = obj.replace || replace
}

/**
 * Allows you to reset the variables (mainly for testing).
 */
truncate.reset = () => {
    maxDepth = 10
    maxString = 256
    replace = undefined
}

truncate.reset()

export { truncate }
