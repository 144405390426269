import { DOMHandler } from "./handler"
import { Ticker } from "../../common/ticker"
import { safe } from "../../common/tools"

let LAST_TOUCH_X = null
let LAST_TOUCH_Y = null

class TouchMove extends DOMHandler {
    constructor({ utils, eventsStream, aquireInterval }) {
        super()
        this.startTime = null
        this.lastPosition = { x: 0, y: 0 }
        this.position = { x: 0, y: 0 }

        this.utils = utils
        this.eventsStream = eventsStream
        this.ticker = new Ticker({ interval: aquireInterval })

        this.eventFunc = (e) => safe(this.Handler.bind(this), e)
    }

    Register() {
        this.startTime = null
        window.addEventListener("touchstart", this.eventFunc, true)
        window.addEventListener("touchend", this.eventFunc, true)
        window.addEventListener("touchmove", this.eventFunc, true)
        window.addEventListener("touchcancel", this.eventFunc, true)
        this.ticker.Start(this.Trigger.bind(this))

        return this.Get()
    }

    Unregister() {
        this.startTime = null
        window.addEventListener("touchstart", this.eventFunc, true)
        window.addEventListener("touchend", this.eventFunc, true)
        window.addEventListener("touchmove", this.eventFunc, true)
        window.addEventListener("touchcancel", this.eventFunc, true)
        this.ticker.Stop()
    }

    isValueChanged() {
        const { x, y } = this.lastPosition
        return x != this.position.x || y != this.position.y
    }

    Handler(a, c) {
        if (!this.startTime) {
            this.startTime = this.utils.Time.Duration()
        }

        if (void 0 !== a.changedTouches) {
            for (var d = 0; d < a.changedTouches.length; ++d) {
                var g = a.changedTouches[d]
                const x = parseInt(g.clientX, 10)
                const y = parseInt(g.clientY, 10)
                const rx = parseInt(g.pageX, 10)
                const ry = parseInt(g.pageY, 10)

                LAST_TOUCH_X = parseInt(g.clientX, 10)
                LAST_TOUCH_Y = parseInt(g.clientY, 10)

                this.position = {
                    x: x,
                    y: y,
                    json_data: { rx: rx, ry: ry },
                }
            }
        }
    }

    Trigger() {
        if (!this.isValueChanged()) {
            this.startTime = null
            return
        }

        this.lastPosition = { x: this.position.x, y: this.position.y, json_data: this.position.json_data }
        this.eventsStream.Add(this.name, this.position, this.startTime)
        this.startTime = null
    }

    Get() {
        return LAST_TOUCH_X != null && LAST_TOUCH_Y != null ? { x: LAST_TOUCH_X, y: LAST_TOUCH_Y } : null
    }
}

export { TouchMove }
