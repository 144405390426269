class Time {
    constructor() {
        this.Reset()
    }

    // Reset resets time from page view
    Reset() {
        this.time = this.Now()
    }

    Duration() {
        return this.Now() - this.time
    }

    Now() {
        return new Date().getTime()
    }

    IsValidDateObject(date) {
        return date instanceof Date && !isNaN(date.valueOf())
    }
    IsDate(date) {
        return this.IsValidDateObject(new Date(date))
    }
}

export { Time }
