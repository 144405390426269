import { Ticker } from "./ticker"
import { debug } from "./tools"

const ctxLog = (...args) => {
    debug("Throttle", ...args)
}

class Throttle {
    constructor({ limit, interval, onLimit }) {
        this.remaining = limit
        this.limit = limit
        this.onLimit = onLimit
        this.ticker = new Ticker({ interval, fn: this.onTick.bind(this) })
        this.counter = 0
    }

    onTick() {
        this.remaining = this.limit
        this.counter = 0
    }

    Use(fn) {
        ctxLog("Use:", this.counter++)
        if (0 == this.remaining) {
            this.onLimit()
        } else if (0 < this.remaining) {
            fn()
        }
        this.remaining--
    }

    Stop() {
        this.ticker.Stop()
        return this
    }

    Start() {
        this.ticker.Start(this.onTick.bind(this))
    }
}

export { Throttle }
