import { Handler } from "./handler"
import { events } from "../events/typedef"
import { debug } from "../../common/tools"

const ctxLog = (...args) => {
    debug("PerformanceHandler", ...args)
}

const ms = 1000

export class PerformanceHandler extends Handler {
    constructor({ eventsStream } = {}) {
        super()
        this.eventsStream = eventsStream

        this.fcp = null
        this.domLoaded = null
        this.pageLoaded = null

        this.cb = null
        this.backOffIntervalMs = 500
        this.backOffMaxMs = ms * 30

        this.streamBuffer = {
            fcp: null,
            domLoaded: null,
            pageLoaded: null,
        }
    }

    Register() {}

    Unregister() {}

    Init() {
        this.observe()
    }

    addEvent() {
        const data = this.getPerformanceInfo()

        const jsonData = {}
        if (data.fcp && !this.streamBuffer.fcp) {
            jsonData.first_contentful_paint = data.fcp
        }
        if (data.domLoaded && !this.streamBuffer.domLoaded) {
            jsonData.dom_loaded = data.domLoaded
        }
        if (data.pageLoaded && !this.streamBuffer.pageLoaded) {
            jsonData.page_loaded = data.pageLoaded
        }

        if (!Object.keys(jsonData).length) {
            return
        }

        ctxLog("Add Performance Event", JSON.stringify(jsonData))

        this.eventsStream.Add(events.PERFORMANCE, {
            json_data: jsonData,
        })

        this.streamBuffer = {
            fcp: data.fcp,
            domLoaded: data.domLoaded,
            pageLoaded: data.pageLoaded,
        }
    }

    getPerformanceInfo() {
        return {
            fcp: this.fcp,
            domLoaded: this.domLoaded,
            pageLoaded: this.pageLoaded,
        }
    }

    observe() {
        const performance = window.performance

        if (!performance) {
            return
        }

        if ("PerformanceObserver" in window) {
            this.observerAPI()
        }

        const perfEntries = performance.getEntriesByType("navigation")

        if (perfEntries && perfEntries.length) {
            this.navigationAPI(perfEntries)
            return
        }

        if (performance.timing) {
            this.deprecatedTimingAPI()
        }
    }

    observerAPI() {
        ctxLog("use PerformanceObserver API")

        const perfObserver = new PerformanceObserver((list, obj) => {
            const entries = list.getEntries()
            let domLoaded
            let pageLoaded

            for (let i = 0; i < entries.length; i++) {
                const perf = entries[i]
                if (perf instanceof PerformanceNavigationTiming) {
                    domLoaded = perf.domComplete - perf.requestStart
                    pageLoaded = perf.loadEventEnd - perf.requestStart
                    break
                }
            }

            this.domLoaded = this.castPerformanceVal(domLoaded)
            this.pageLoaded = this.castPerformanceVal(pageLoaded)

            this.addEvent()

            if (!this.domLoaded || !this.pageLoaded) {
                return
            }
            perfObserver.disconnect()
        })
        perfObserver.observe({
            type: "navigation",
            buffered: true,
        })

        const paintObserver = new PerformanceObserver((entryList) => {
            let fcp = -1

            for (const entry of entryList.getEntriesByName("first-contentful-paint")) {
                fcp = entry.startTime
            }

            this.fcp = this.castPerformanceVal(fcp)

            this.addEvent()

            if (!this.fcp) {
                return
            }

            paintObserver.disconnect()
        })
        paintObserver.observe({ type: "paint", buffered: true })
    }

    navigationAPI(entries) {
        ctxLog("use PerformanceNavigationTiming API")

        let domLoaded = ""
        let pageLoaded = ""

        for (let i = 0; i < entries.length; i++) {
            const perf = entries[i]
            domLoaded = perf.domComplete - perf.requestStart
            pageLoaded = perf.loadEventEnd - perf.requestStart
        }

        this.domLoaded = this.castPerformanceVal(domLoaded)
        this.pageLoaded = this.castPerformanceVal(pageLoaded)
        this.fcp = this.castPerformanceVal(this.getFCP())

        this.backOff(
            () => {
                return this.domLoaded && this.pageLoaded
            },
            () => this.addEvent(),
        )
    }

    deprecatedTimingAPI() {
        ctxLog("use Timing API")

        const p = window.performance

        this.domLoaded = this.castPerformanceVal(p.timing.domComplete - p.timing.requestStart)
        this.pageLoaded = this.castPerformanceVal(p.timing.loadEventEnd - p.timing.requestStart)
        this.fcp = this.castPerformanceVal(this.getFCP())

        this.backOff(
            () => {
                return this.domLoaded && this.pageLoaded
            },
            () => this.addEvent(),
        )
    }

    getFCP() {
        const e = window.performance.getEntriesByType("paint")

        let fcp = -1
        if (e) {
            for (let i = 0; i < e.length; i++) {
                if (e[i].name === "first-contentful-paint") fcp = e[i].startTime
            }
        }

        return fcp
    }

    castPerformanceVal(val) {
        const valInt = parseInt(val, 10)

        if (!valInt || isNaN(valInt) || valInt <= 0) {
            return 0
        }

        return valInt
    }

    backOff(conditionCb, backoffCb) {
        if (typeof conditionCb != "function" || typeof backoffCb != "function") {
            return
        }

        if (conditionCb()) {
            backoffCb()
            return
        }

        const start = +Date.now()

        let i = 0

        const tick = setInterval(() => {
            i++
            if (i >= 10) {
                clearInterval(tick)
                backoffCb()
                return
            }
            const now = +Date.now()
            const delta = now - start

            const condition = conditionCb()

            if (condition || delta >= this.backOffMaxMs) {
                clearInterval(tick)
                backoffCb()
            }
        }, this.backOffIntervalMs)
    }
}
