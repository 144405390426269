import { safe } from "./tools"

class Ticker {
    constructor({ interval, fn }) {
        this.interval = interval
        this.id = -1

        if (fn) {
            this.Start(fn)
        }
    }

    IsOn() {
        return this.id > -1
    }

    Start(fn) {
        if (this.IsOn()) {
            return
        }
        this.tick = () => {
            safe(fn)
        }
        this.id = window.setInterval(this.tick, this.interval)
    }

    Stop() {
        if (!this.IsOn()) {
            return
        }
        window.clearInterval(this.id)
        this.id = -1
        this.tick = () => {}
    }
}

export { Ticker }
