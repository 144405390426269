import { DOMHandler } from "./handler"
import { safe } from "../../common/tools"

class WindowBlurHandler extends DOMHandler {
    constructor({ utils, eventsStream }) {
        super()
        this.utils = utils
        this.eventsStream = eventsStream

        this.eventHandler = (e) => safe(this.Handler.bind(this), e)
    }

    Register() {
        window.addEventListener("blur", this.eventHandler)
    }

    Unregister() {
        window.removeEventListener("blur", this.eventHandler)
    }

    Handler(e) {
        this.eventsStream.Add(this.name)
    }
}

export { WindowBlurHandler }
