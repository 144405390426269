import { debug } from "../common/tools"
import forEach from "for-each"

const ctxLog = (...args) => {
    debug("Conditions", ...args)
}

class Conditions {
    constructor({ globalEvents }) {
        this.globalEvents = globalEvents
        this.enabled = false
        this.list = []
        this.eventsConditions = []
        this.Reset()

        this.operators = {
            start: (base, value) => {
                return base.indexOf(value) == 0
            },
            contain: (base, value) => {
                return base.indexOf(value) > -1
            },
            end: (base, value) => {
                return base.indexOf(value) == base.length - value.length
            },
            eq: (base, value) => {
                return base == value
            },
            neq: (base, value) => {
                return base !== value
            },
            gt: (base, value) => {
                return base > value
            },
            gte: (base, value) => {
                return base >= value
            },
            lt: (base, value) => {
                return base < value
            },
            lte: (base, value) => {
                return base <= value
            },
        }
    }

    Set(condtions) {
        ctxLog("set:", condtions)
        forEach(condtions, (c) => {
            switch (c.type) {
                case "event":
                    this.conds.events.push(c)
                    this.eventsConditions = c.name
                    break
                case "session":
                    this.conds.session.push(c)
                    break
                case "pageView":
                    this.conds.pageview.push(c)
                    break
                default:
                    ctxLog("unknown condition type:", c.type)
                    break
            }
        })
        this.checkFulfilled()
    }

    Restart(conds) {
        this.Reset()
        this.Set(conds)
        this.Enable()
    }

    Reset() {
        this.done = false
        this.conds = {
            events: [],
            session: [],
            pageview: [],
        }
        this.eventsConditions = []
    }

    Enable() {
        this.enabled = true
    }

    Disable() {
        this.enabled = false
    }

    IsEnabled() {
        return this.enabled
    }

    IsEmpty() {
        return this.list.length === 0
    }

    IsSet() {
        return !this.IsEmpty()
    }

    IsFulfilled() {
        return this.done || (this.conds.events.length === 0 && this.conds.session.length === 0 && this.conds.pageview.length === 0)
    }

    // EventsConditions returns list of conditions set by user or default one.
    // It's similar to this.conds.events but does not change during a condition check.
    // Useful to know which event conditions were set historically.
    EventsConditions() {
        return this.eventsConditions
    }

    checkFulfilled() {
        if (!this.done && this.IsFulfilled()) {
            this.done = true
            this.globalEvents.call("conditions.fulfilled")
        }
    }

    CheckEventData(event) {
        if (!this.IsEnabled() || this.IsFulfilled()) {
            return
        }
        let ids = []
        forEach(this.conds.events, (c, idx) => {
            if (Array.isArray(c.name)) {
                let found = false
                forEach(c.name, (name) => {
                    if (event.name == name) found = true
                })
                if (found) {
                    ctxLog("fulfilled:", c)
                    ids.push(idx)
                }
            } else {
                if (event.name == c.name) {
                    if (c.operator && c.value && c.key) {
                        if (!this.operators[c.operator]) {
                            console.warn("[LiveSession] invalid condition operator for:", c)
                        } else {
                            if (!event[c.key]) {
                                console.warn("[LiveSession] invalid condition key for:", c)
                            } else {
                                ctxLog("check condition:", c, event)
                                if (this.operators[c.operator](event[c.key], c.value)) {
                                    ctxLog("fulfilled:", c)
                                    ids.push(idx)
                                }
                            }
                        }
                    } else {
                        ctxLog("fulfilled:", c)
                        ids.push(idx)
                    }
                }
            }
        })
        this.clearConds(this.conds.events, ids)
        this.checkFulfilled()
    }
    CheckSessionData(session) {
        if (!this.IsEnabled() || this.IsFulfilled()) {
            return
        }
        this.checkFulfilledData(this.conds.session, session)
        this.checkFulfilled()
    }
    CheckPageViewData(pageView) {
        if (!this.IsEnabled() || this.IsFulfilled()) {
            return
        }
        this.checkFulfilledData(this.conds.pageview, pageView)
        this.checkFulfilled()
    }

    checkFulfilledData(conds, source) {
        let ids = []
        forEach(conds, (c, idx) => {
            if (Array.isArray(c.name)) {
                let found = false
                forEach(c.name, (name) => {
                    if (source.name == name) found = true
                })
                if (found) {
                    ctxLog("fulfilled:", c)
                    ids.push(idx)
                }
            } else {
                let v = get(source, c.name)
                if (!this.operators[c.operator]) {
                    console.warn("[LiveSession] invalid condition operator for:", c)
                } else {
                    if (this.operators[c.operator](v, c.value)) {
                        ctxLog("fulfilled:", c)
                        ids.push(idx)
                    }
                }
            }
        })
        this.clearConds(conds, ids)
    }

    clearConds(conds, ids) {
        for (let i = conds.length - 1; i >= 0; i--) {
            const id = ids.indexOf(i)
            if (id > -1) {
                conds.splice(ids[id], 1)
            }
        }
    }
}

export { Conditions }
