import { MutationSummary } from "./mutation-summary"
import { isEmpty } from "./../helpers/objectHelper"
import { debug } from "../common/tools";
// import LZString from './lz-string';

const ctxLog = (...args) => {
    debug("tree-mirror-client", ...args)
}

// TODO: shadowroot focus
// TODO: shadow slots
// TODO: shadow stylesheet

class TreeMirrorClient {
    constructor(target, mirror, testingQueries, utils) {
        const _this = this
        let queries = [{ all: true }]

        this.mirror = mirror
        this.utils = utils
        this.nextId = 1
        this.knownNodes = new MutationSummary.NodeMap()
        this.shadowRoots = {}

        const rootId = this.serializeNode(target).id
        this.mirror.initialize(rootId, this.getSerializedDocument(target))

        if (testingQueries) {
            queries = queries.concat(testingQueries)
        }

        this.mutationSummary = new MutationSummary({
            rootNode: target,
            callback: (summaries) => {
                _this.applyChanged(summaries)
            },
            queries: queries,
        })
    }

    attachShadowRoot(root) {
        if (!root) {
            ctxLog("Error:", "shadow root not exists")
            return
        }

        if (this.getNodeHostID(root) !== undefined) {
            // shadow root is already processed by serializeNode/MutationSummary
            return
        }

        const { host } = root
        const id = this.knownNodes.get(host)

        if (id === undefined) {
            /* TODO:
                Add more similar test cases because I'm not sure if every dynamic attaching will be handled.
                Currently it works because of multiple levels (mutation summary for shadow roots).
                But I'm not sure about all edge cases.
             */
            return
        }

        this.setNodeHostID(root, id) // attach sHi for dynamic shadow root

        // register dynamic, non-handled shadow root and add to DOMChange event stream
        const added = this.serializeNode(root, false)
        this.mirror.applyChanged([], [added], [], [])
    }

    compress(data) {
        // this this.lzString.compressToUint8Array(data)
        return data
    }

    getSerializedDocument(target) {
        const children = []

        for (let child = target.firstChild; child; child = child.nextSibling) {
            const hostID = this.getNodeHostID(target)
            if (hostID !== undefined) {
                this.setNodeHostID(child, hostID)
            }
            children.push(this.serializeNode(child, true))
        }

        return children
    }

    disconnect() {
        if (this.mutationSummary) {
            this.mutationSummary.disconnect()
            this.mutationSummary = undefined
        }

        for (const nodeID in this.shadowRoots) {
            const root = this.shadowRoots[nodeID]
            this.mirror.removeShadowListeners(root)
        }
    }

    rememberNode(node) {
        var id = this.nextId++
        this.knownNodes.set(node, id)
        node._lsid = id
        return id
    }

    getNodeHostID(node) {
        const id = node._lshostid

        if (typeof id !== "number") {
            return undefined
        }

        return id
    }

    setNodeHostID(node, id) {
        node._lshostid = id
    }

    forgetNode(node) {
        this.knownNodes["delete"](node)
    }

    serializeNode(node, recursive) {
        if (node === null) return null

        var id = this.knownNodes.get(node)
        const shadowHostID = this.getNodeHostID(node)

        if (id !== undefined) {
            const data = {
                i: id,
            }

            if (shadowHostID !== undefined) {
                data.sHi = shadowHostID
            }

            return data
        }

        var data = {
            nT: node.nodeType,
            i: this.rememberNode(node),
        }

        if (shadowHostID !== undefined) {
            data.sHi = shadowHostID
        }

        const elm = node

        switch (data.nT) {
            case Node.DOCUMENT_TYPE_NODE:
                var docType = node
                data.n = docType.name
                data.p = docType.publicId
                data.s = docType.systemId

                if (node && node.adoptedStyleSheets) {
                    this.mirror.onAdoptedStyleSheets(node)
                }

                break
            case Node.DOCUMENT_FRAGMENT_NODE: {
                const isShadowRoot = this.isTargetShadowRoot(elm)

                if (!isShadowRoot) {
                    break
                }

                if (node && node.adoptedStyleSheets) {
                    this.mirror.onAdoptedStyleSheets(node)
                }

                data.sR = true
                data.cN = []

                this.registerShadowRoot(elm, data.i)

                // Add for every host shadow roots in every node levels, it's useful for unregister cleanup.
                // If dom deletion was on mutation summary outside shadowroot then child mutation summary are not triggered
                // so we must map every shadow roots for host to delete properly.
                // TODO: consider better solution - better mutation summary attaching?
                if (elm.host) {
                    if (!elm.host._lsshadowroots) {
                        elm.host._lsshadowroots = new Set()
                    }

                    const traversed = {}
                    let host = elm.host

                    while(host) {
                        const hostID = this.getNodeHostID(host)
                        if (traversed[hostID]) {
                            break
                        }

                        // id does not exists because it's root host
                        if (hostID === undefined) {
                            elm.host._lsshadowroots.add(elm._lsid)
                            break
                        }

                        host = this.knownNodes.nodes[hostID]
                        if (!host) {
                            break
                        }

                        traversed[hostID] = true

                        if (!host._lsshadowroots) {
                            host._lsshadowroots = new Set()
                        }

                        host._lsshadowroots.add(elm._lsid)
                    }
                }

                const seekChildren = recursive && elm.childNodes.length

                if (seekChildren) {
                    data.cN = this.getSerializedDocument(elm)
                }

                break
            }
            case Node.COMMENT_NODE:
            case Node.TEXT_NODE:
                if (node.parentNode) {
                    if (node.parentNode.tagName === "TEXTAREA") {
                        data.tC = this.mirror.inputWatcher.getElementValue(node.parentNode, node.textContent)
                        break
                    }
                }
                if (this.utils.DOM.isElementContentDisabled(node.parentNode)) {
                    data.tC = this.utils.StarifyString(node.textContent.trim())
                } else {
                    data.tC = node.textContent
                }

                break
            case Node.ELEMENT_NODE:
                data.tN = elm.tagName
                data.a = {}

                const isShadowHost = elm.shadowRoot && elm.shadowRoot.host === elm

                if (isShadowHost) {
                    data.sH = true
                }

                for (var i = 0; i < elm.attributes.length; i++) {
                    var attr = elm.attributes[i]
                    data.a[attr.name.toLowerCase()] = attr.value
                }

                switch (node.tagName) {
                    case "IMG":
                        const self = this

                        if (this.utils.DOM.shouldMaskIMG(node)) {
                            this.utils.DOM.maskIMG(data, node, (nodeData) => {
                                self.mirror.applyAttributesNodeData(nodeData)
                            })
                        }

                        break
                    case "INPUT":
                    case "SELECT":
                    case "TEXTAREA":
                        data.a["value"] = this.mirror.inputWatcher.getElementValue(node, node.value)
                        break
                }

                if (elm.tagName == "SCRIPT" || elm.tagName == "NOSCRIPT") {
                    break
                }

                const seekChildren = recursive && elm.childNodes.length

                if (isShadowHost || seekChildren) {
                    data.cN = []
                }

                if (isShadowHost) {
                    this.setNodeHostID(elm.shadowRoot, data.i)
                    const shadowDom = this.serializeNode(elm.shadowRoot, true)
                    data.cN.push(shadowDom)
                }

                if (seekChildren) {
                    data.cN.push(...this.getSerializedDocument(elm))
                }

                break
        }

        if (this.mirror.visitNode) this.mirror.visitNode(node, data, {
            serializeNode: this.serializeNode.bind(this)
        })
        return this.compressNode(data)
    }

    serializeAddedAndMoved(added, reparented, reordered) {
        var _this = this
        var all = added.concat(reparented).concat(reordered)
        var parentMap = new MutationSummary.NodeMap()
        all.forEach(function (node) {
            var parent = node.parentNode
            var children = parentMap.get(parent)
            if (!children) {
                children = new MutationSummary.NodeMap()
                parentMap.set(parent, children)
            }
            children.set(node, true)
        })
        var moved = []
        parentMap.keys().forEach(function (parent) {
            var children = parentMap.get(parent)
            var keys = children.keys()
            while (keys.length) {
                var node = keys[0]
                while (node.previousSibling && children.has(node.previousSibling)) node = node.previousSibling
                while (node && children.has(node)) {
                    var data = _this.serializeNode(node)
                    data.previousSibling = _this.serializeNode(node.previousSibling)
                    data.parentNode = _this.serializeNode(node.parentNode)
                    moved.push(data)
                    children["delete"](node)
                    node = node.nextSibling
                }
                var keys = children.keys()
            }
        })
        return moved
    }

    serializeAttributeChanges(attributeChanged) {
        var _this = this
        var map = new MutationSummary.NodeMap()

        Object.keys(attributeChanged).forEach(function (attrName) {
            attributeChanged[attrName].forEach(function (element) {
                var record = map.get(element)
                if (!record) {
                    record = _this.serializeNode(element)
                    record.a = {}
                    map.set(element, record)
                }
                record.a[attrName] = _this.compress(element.getAttribute(attrName))
            })
        })
        const res = []
        map.keys().map(function (node) {
            const n = map.get(node)
            switch (node.tagName) {
                case "INPUT":
                case "SELECT":
                case "TEXTAREA":
                    if (node.value) {
                        delete n.a.value
                        break
                    }
            }
            if (isEmpty(n.a)) return
            res.push(n)
        })
        return res
    }

    compressNode(node) {
        if (node.tC || node.a) {
            node.c = 1
        }
        if (node.tC) {
            node.tC = this.compress(node.tC)
        }
        if (node.a) {
            var _this = this
            Object.keys(node.a).forEach(function (attributeName) {
                node.a[attributeName] = _this.compress(node.a[attributeName])
            })
        }
        return node
    }

    applyChanged(summaries) {
        var _this = this
        var summary = summaries[0]
        var removed = summary.removed.map(function (node) {
            return _this.serializeNode(node)
        })
        var moved = this.serializeAddedAndMoved(summary.added, summary.reparented, summary.reordered)
        var attributes = this.serializeAttributeChanges(summary.attributeChanged)
        var text = summary.characterDataChanged.map(function (node) {
            var data = _this.serializeNode(node)

            if (node.parentNode && node.parentNode.tagName === "TEXTAREA") {
                data.tC = _this.mirror.inputWatcher.getElementValue(node.parentNode, node.textContent)
            }

            if (_this.utils.DOM.isElementContentDisabled(node.parentNode)) {
                data.tC = _this.utils.StarifyString(node.textContent.trim())
            } else {
                data.tC = node.textContent
            }

            return data
        })
        if (!isEmpty(removed) || !isEmpty(moved) || !isEmpty(attributes) || !isEmpty(text)) {
            this.mirror.applyChanged(removed, moved, attributes, text)
        }

        summary.removed.forEach(node => {
            if (node && node.shadowRoot) {
                const id = _this.knownNodes.get(node.shadowRoot)
                if (id !== undefined) {
                    // is already deleted
                    if (!this.shadowRoots[id]) {
                        return
                    }

                    _this.unregisterShadowRoot(id)
                }
            }

            if (node._lsshadowroots) {
                for (const shadowRootNodeMapID of node._lsshadowroots) {
                    const id = _this.knownNodes.values[shadowRootNodeMapID]
                    if (id !== undefined) {
                        // is already deleted
                        if (!this.shadowRoots[id]) {
                            continue
                        }

                        _this.unregisterShadowRoot(id)
                    }
                }
            }
            _this.forgetNode(node)
        })
    }

    registerShadowRoot(shadowRoot, rootID) {
        const self = this

        // TODO: check heap/stack in browsers (self-managed vs browser memory lifecycle)
        shadowRoot._lsmsummary = new MutationSummary({
            rootNode: shadowRoot,
            callback: (summaries) => {
                self.applyChanged(summaries)
            },
            queries: [{ all: true }],
        })

        this.mirror.addShadowListeners(shadowRoot)

        this.shadowRoots[rootID] = shadowRoot
        ctxLog( "registered shadow root", rootID)
    }

    unregisterShadowRoot(rootID) {
        const root = this.shadowRoots[rootID]
        if (!root) {
            ctxLog( "Errror:", "shadow root does not exists", rootID)
            return
        }

        delete this.shadowRoots[rootID]
        this.mirror.removeShadowListeners(root)

        ctxLog( "unregistered shadow root", rootID)
    }

    isTargetShadowRoot(target) {
        return target.nodeName === "#document-fragment" && target.constructor.name === "ShadowRoot"
    }
}

export { TreeMirrorClient }
