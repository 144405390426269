import { DOMHandler } from "./handler"

class MouseDownHandler extends DOMHandler {
    constructor({ utils, eventsStream }) {
        super()
        this.utils = utils
        this.eventsStream = eventsStream

        this.eventFunc = this.Handler.bind(this)
    }

    Register() {
        window.addEventListener("mousedown", this.eventFunc, true)
    }

    Unregister() {
        window.removeEventListener("mousedown", this.eventFunc, true)
    }

    Handler(e) {
        if (!e.isTrusted) return
        this.eventsStream.Add(this.name, {
            x: e.pageX,
            y: e.pageY,
            path: this.utils.DOM.FullPath(e.target),
            el: this.utils.DOM.ElementName(e.target),
        })
    }
}

export { MouseDownHandler }
