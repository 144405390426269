import { debug } from "./../common/tools"
import { Ticker } from "./../common/ticker"
import { Snapshot } from "./events/snapshot"
import { IUtils } from "./utils/Utils"

const BUNDLER_ERROR_BEACON_INVALID_PAGE_VIEW_ID = "beacon_invalid_page_view_id"

const MAX_SNAPSHOT_SIZE = 1024

const ctxLog = (...args) => {
    debug("EventsBundler", ...args)
}

class BundlerError {
    constructor(type) {
        this.type = type
    }
}

class EventsBundler {
    utils: IUtils
    constructor({ eventsStream, transport, globalEvents, activity, pageView, utils }) {
        this.eventsStream = eventsStream
        this.transport = transport
        this.globalEvents = globalEvents
        this.activity = activity
        this.pageView = pageView
        this.utils = utils
        this.activityTimeSum = 0
        this.ticker = new Ticker({ interval: 2000 })
        this.Reset()

        window.addEventListener("beforeunload", this.onUnload.bind(this), false)
        window.addEventListener("pagehide", this.onUnload.bind(this), false)
    }

    Reset() {
        this.seq = 0
    }

    onUnload(e) {
        if (typeof this.pageView.ID() !== "string") {
            // page view id may not exist yet - it can happen if request take a long time
            this.send(true, new BundlerError(BUNDLER_ERROR_BEACON_INVALID_PAGE_VIEW_ID))
            return
        }
        this.send(true)
    }

    Start() {
        this.ticker.Start(this.send.bind(this))
    }

    Stop() {
        this.ticker.Stop()
    }

    nextSequence() {
        this.seq++
    }
    sequence() {
        return this.seq
    }

    send(useBeacon = false, error) {
        const snapshot = new Snapshot(this.eventsStream.Dump(MAX_SNAPSHOT_SIZE))
        if (snapshot.IsEmpty()) {
            return
        }
        const { activeTime, inactivity } = this.activity.getActivityTime({ flush: true })

        ctxLog(`Snapshot size: ${snapshot.Size()}, activeTime: ${activeTime}`)
        ctxLog(`Snapshot size: ${snapshot.Size()}, inactivity: ${JSON.stringify(inactivity)}`)

        const tabID = this.tabID()

        // it's incremented because es.ActiveTimeJSON is a backend-only event but sequence comes from record-api
        // to keep this number consistent backend and tracking-code must increment this number.
        this.utils.EventSeq.Next()

        this.transport.SendBundle(
            { events: snapshot.Events(), inactivity, activeTime, tabID },
            () => {
                this.nextSequence()
            },
            (error) => {
                if (error && error.type == "session_expired") {
                    return this.Stop()
                }

                if (snapshot.Size() > MAX_SNAPSHOT_SIZE) {
                    this.eventsStream.Clear()
                    this.Stop()

                    return this.globalEvents.call("bundler.stream_overflow")
                }
                this.eventsStream.Join(snapshot.Events())
            },
            {
                seq: this.sequence(),
                ad: activeTime,
            },
            useBeacon,
            error,
        )
    }

    tabID() {
        // TODO: not support tab 'duplicate'
        if (!window?.sessionStorage?.tabID) {
            window.sessionStorage.tabID = this.utils.UUID()
        }
        return window?.sessionStorage?.tabID
    }
}

export { EventsBundler }
