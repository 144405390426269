import { DOMHandler } from "./handler"
import { Ticker } from "../../common/ticker"
import { safe } from "../../common/tools"

let LAST_MOUSE_X = null
let LAST_MOUSE_Y = null

class MouseMoveHandler extends DOMHandler {
    constructor({ utils, eventsStream, aquireInterval }) {
        super()
        this.startTime = null
        this.lastPosition = { x: 0, y: 0 }
        this.position = { x: 0, y: 0 }

        this.utils = utils
        this.eventsStream = eventsStream
        this.ticker = new Ticker({ interval: aquireInterval })

        this.eventFunc = (e) => safe(this.Handler.bind(this), e)
    }

    Register() {
        this.startTime = null
        window.addEventListener("mousemove", this.eventFunc, true)
        this.ticker.Start(this.Trigger.bind(this))

        return this.Get()
    }

    Unregister() {
        this.startTime = null
        window.removeEventListener("mousemove", this.eventFunc, true)
        this.ticker.Stop()
    }

    isValueChanged() {
        const { x, y } = this.lastPosition
        return x != this.position.x || y != this.position.y
    }

    Trigger() {
        if (!this.isValueChanged()) {
            this.startTime = null
            return
        }

        this.lastPosition = { x: this.position.x, y: this.position.y, json_data: this.position.json_data }
        this.eventsStream.Add(this.name, this.position, this.startTime)

        this.startTime = null
    }

    Handler(e) {
        LAST_MOUSE_X = Math.max(parseInt(e.clientX, 10), 0)
        LAST_MOUSE_Y = Math.max(parseInt(e.clientY, 10), 0)

        if (!this.startTime) {
            this.startTime = this.utils.Time.Duration()
        }
        if (!e.target) {
        }

        this.position = {
            x: e.clientX,
            y: e.clientY,
            path: this.utils.DOM.FullPath(e.target),
            el: this.utils.DOM.ElementName(e.target),
            json_data: { v: this.utils.Time.Duration() - this.startTime, rx: e.pageX, ry: e.pageY },
        }
    }

    Get() {
        return LAST_MOUSE_X != null && LAST_MOUSE_Y != null ? { x: LAST_MOUSE_X, y: LAST_MOUSE_Y } : null
    }
}

export { MouseMoveHandler }
