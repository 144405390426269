import { debug } from "./../common/tools"
import { events } from "./../recorder/events/typedef"
import { lastIndexOf } from "../helpers/objectHelper"

const ctxLog = (...args) => {
    debug("EventsBundler", ...args)
}

const MAX_TIME = 4000

type Inactivity = number[][]

interface IActivityTime {
    activeTime: number
    inactivity: Inactivity
}
interface IEvent {
    name: string
    time: number
    isActive: boolean
}

export interface IActivity {
    applyEvent: (name: string, time: number) => void
    getActivityTime: (opt: { flush: boolean }) => IActivityTime
    Clear: () => void
}

class Activity implements IActivity {
    acitivtyEvents = [
        events.MOUSE_MOVE,
        events.TOUCH_MOVE,
        events.MOUSE_CLICK,
        events.VALUE_CHANGE,
        events.LOCATION_CHANGE,
        events.WINDOW_SCROLL,
        events.SCROLL,
    ]

    activeTime: number
    currentTime: number
    lastActiveEventTime: number
    lastActiveEventTimeSent: number
    bundleStartTimestamp: number
    lastEventIsActive: boolean
    bundleInited: boolean
    events: IEvent[]

    constructor() {
        this.bundleInited = false
        this.currentTime = 0
        this.lastActiveEventTime = 0
        this.lastActiveEventTimeSent = 0
        this.bundleStartTimestamp = 0
        this.lastEventIsActive = false
        this.events = []
        this.activeTime = 0
        this.getActivityTime = this.getActivityTime.bind(this)
    }

    Clear() {
        this.bundleInited = false
        this.currentTime = 0
        this.lastActiveEventTime = 0
        this.lastActiveEventTimeSent = 0
        this.bundleStartTimestamp = 0
        this.lastEventIsActive = false
        this.events = []
        this.activeTime = 0
        this.getActivityTime = this.getActivityTime.bind(this)
    }

    isUserActionEvent(eventName: string): boolean {
        return this.acitivtyEvents.indexOf(eventName) > -1
    }

    applyEvent(name: string, time: number): void {
        const isActive = this.isUserActionEvent(name)
        this.bundleStartTimestamp = time
        this.lastEventIsActive = isActive
        this.events.push({ name, time, isActive })
    }

    getActivityTime(opt = { flush: false }): IActivityTime {
        const { flush } = opt
        const inactivity = [] as Inactivity
        this.activeTime = 0
        const sortedEvents = this.events.sort((a, b) => a.time - b.time)
        const activeEventExists = sortedEvents.findIndex((x) => x.isActive === true) > -1
        const lastIndexOfActiveEvent = lastIndexOf(sortedEvents as [], (x) => x.isActive === true)
        const bundleTime = this.bundleStartTimestamp - this.currentTime
        if (!activeEventExists && bundleTime >= MAX_TIME) {
            inactivity.push([this.currentTime, bundleTime])
            this.currentTime = this.bundleStartTimestamp
        } else {
            sortedEvents.forEach(({ time, isActive }, index: number) => {
                if (isActive) {
                    const isLastActiveEvent = lastIndexOfActiveEvent === index
                    const delta = time - this.lastActiveEventTime
                    const lastEventDelta = this.bundleStartTimestamp - time
                    const isLastEventInactiveEvent = !this.lastEventIsActive && isLastActiveEvent && lastEventDelta > MAX_TIME
                    if (time >= this.currentTime) {
                        if (this.bundleInited) {
                            if (delta < MAX_TIME) {
                                this.activeTime += delta
                            } else {
                                const bundleDelta = time - this.currentTime
                                if (bundleDelta) {
                                    inactivity.push([this.currentTime, bundleDelta])
                                }
                            }
                        }
                        if (isLastEventInactiveEvent) {
                            const bundleDelta = this.bundleStartTimestamp - time
                            inactivity.push([time, bundleDelta])
                        }
                        this.lastActiveEventTime = time
                        this.bundleInited = true
                        this.currentTime = isLastEventInactiveEvent ? this.bundleStartTimestamp : time
                        this.lastActiveEventTimeSent = time
                    } else {
                        ctxLog(`event time ${time} > bundle last event ${this.currentTime}`)
                        this.currentTime = time
                    }
                }
            })
        }

        const result = this.activeTime > 0 ? this.activeTime : 0

        if (flush) {
            this.flush()
        }

        return { activeTime: result, inactivity }
    }

    flush(): void {
        this.activeTime = 0
        this.events = []
    }
}

export { Activity }
