import { BugTracker } from "./bugtracker"

const bugtracker = new BugTracker({ limit: 10 })
const safe = (fn, ...args) => {
    try {
        fn(...args)
    } catch (e) {
        debug("Tools", "Error:", e)
        bugtracker.send(e)
        return false
    }
    return true
}
const enableLogSentry = () => {
    bugtracker.enabled = true
}

const debug = (mod, ...args) => {
    if (window.__ls_debug || window.__ls_debug_force) {
        console.log("[LS][" + mod + "]", ...args)
    }
}

const log = {
    warn: (...args) => {
        console.warn("[LiveSession]", ...args)
    },
    info: (...args) => {
        console.log("[LiveSession]", ...args)
    },
}

const getQueryParam = (variable) => {
    let query = window.location.search.substring(1)
    let vars = query.split("&")
    for (var i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=")
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1])
        }
    }
}

export { safe, log, debug, getQueryParam, bugtracker, enableLogSentry }
