import Cookies from "js-cookie"

import { safe, debug } from "../common/tools"

const COOKIE_VISITOR_ID = "__ls_uid"
const COOKIE_SESSION_ID = "__ls_sid"
const COOKIE_SESSION_EXP = "__ls_exp"
const COOKIE_LIFE_TIME = 3650
const SESSION_LIFE_TIME = 30000

const ctxLog = (...args) => {
    debug("Visitor", ...args)
}

interface StorageOptions {
    expires?: number; // Expiration in days for cookies, or in milliseconds for localStorage
    path?: string; // Path for cookies
    domain?: string;
    sameSite?: 'Lax' | 'Strict' | 'None';
}

type StorageMethod = 'localStorage' | 'cookie'

class Storage {
    public method: StorageMethod;

    constructor(method: StorageMethod = 'cookie', options: StorageOptions = {}) {
        this.method = method;
    }

    setMethod(method: StorageMethod) {
        this.method = method;
    }

    set(key: string, value: string, options: StorageOptions = {}): void {
        if (this.method === 'localStorage') {
            const now = new Date();
            // Assuming expires is provided in days for localStorage for consistency, convert to milliseconds
            const ttl = (options.expires || 1) * 24 * 60 * 60 * 1000;
            const item = {
                value: value,
                expiry: now.getTime() + ttl,
            };
            localStorage.setItem(key, JSON.stringify(item));
        } else { // cookie
            // For js-cookie, expires option is expected to be in days
            Cookies.set(key, value, options);
        }
    }

    get(key: string): string | null {
        if (this.method === 'localStorage') {
            const itemStr = localStorage.getItem(key);
            if (!itemStr) {
                return null;
            }
            const item = JSON.parse(itemStr);
            const now = new Date();
            if (now.getTime() > item.expiry) {
                localStorage.removeItem(key);
                return null;
            }
            return item.value;
        } else { // cookie
            return Cookies.get(key) || null;
        }
    }

    remove(key: string): void {
        if (this.method === 'localStorage') {
            localStorage.removeItem(key);
        } else { // cookie
            Cookies.remove(key);
        }
    }
}


class Visitor {
    private storage: Storage;

    constructor({ utils, options }) {
        this.utils = utils
        this.options = options

        this.storage = new Storage(
            this.options.Get("storageOption") || "cookie"
        );
    }

    Load() {
        this.storage.setMethod(this.options.Get("storageOption") || "cookie")
        this.id = this.storage.get(this.cookieVisitorID());
        const cookieSID = (this.storage.get(this.cookieSessionID()) || "").split(":")
        this.sessionID = cookieSID[0]
        this.clusterID = cookieSID[1] || ""
        this.sessionTimestamp = Number(this.storage.get(this.cookieSessionExp()))

        ctxLog("VisitorID:", this.id, "SID:", this.sessionID, "SEXP:", this.sessionTimestamp, "CID:", this.clusterID)
    }

    getSessionIDWithClusterID() {
        return this.sessionID + ":" + this.clusterID
    }

    setCookie(name, value) {
        this.storage.set(name, value, {
            expires: COOKIE_LIFE_TIME,
            domain: this.options.Get("rootHostname"),
            sameSite: "Lax",
        })

        ctxLog(`Set ${this.storage.method}:`, name, value, {
            expires: COOKIE_LIFE_TIME,
            domain: this.options.Get("rootHostname"),
        })
    }

    Set(visitorID, sessionID, sessionTimestamp, clusterID) {
        this.sessionID = sessionID
        this.id = visitorID
        this.sessionTimestamp = sessionTimestamp
        this.clusterID = clusterID

        this.setCookie(this.cookieVisitorID(), this.id)
        this.setCookie(this.cookieSessionID(), this.getSessionIDWithClusterID())
        this.setCookie(this.cookieSessionExp(), this.sessionTimestamp)
        ctxLog("Set:", this.id, this.sessionID, this.sessionTimestamp, this.clusterID)
    }

    CleanUpSession() {
        this.storage.remove(this.cookieSessionID())
        this.storage.remove(this.cookieSessionExp())
    }

    ID() {
        return this.id
    }

    ClusterID() {
        return this.clusterID
    }

    SessionID() {
        return this.sessionID
    }

    IsValid() {
        const exp = this.SessionExpiration()
        return !!this.ID() && !!this.SessionID() && !!exp && exp * 1000 > this.utils.Time.Now()
    }

    InvalidateSession() {
        const now = parseInt(this.utils.Time.Now() / 1000) - SESSION_LIFE_TIME
        this.sessionTimestamp = now
        this.setCookie(this.cookieSessionExp(), now)
    }

    SessionExpiration() {
        return this.sessionTimestamp
    }

    JSON() {
        return {
            ls_vid: this.ID(),
            ls_sid: this.SessionID(),
            ls_sexp: this.SessionExpiration(),
            ls_clsid: this.ClusterID(),
        }
    }

    cookieVisitorID() {
        return this.withCookieSuffix(COOKIE_VISITOR_ID)
    }

    cookieSessionID() {
        return this.withCookieSuffix(COOKIE_SESSION_ID)
    }

    cookieSessionExp() {
        return this.withCookieSuffix(COOKIE_SESSION_EXP)
    }

    withCookieSuffix(cookie) {
        if (this.options.Get("rootHostname")) {
            return `${cookie}-${this.options.TrackID()}`
        }

        return cookie
    }
}

export { Visitor }
