interface ISettings {
    keystrokes: boolean
    rootHostname: null
    minDuration: number
}

interface IOptions {
    AccountID(): void
    WebsiteID(): string
    TrackID(): string
    SetAccountID(id: string): void
    SetWebsiteID(id: string): void
    Get(name: string): ISettings
    Set(name: string, value: string): void
}

class Options implements IOptions {
    private settings: ISettings
    private accountID: string
    private websiteID: string
    constructor() {
        this.accountID = "-1"
        this.websiteID = "-1"
        this.settings = {
            keystrokes: false,
            rootHostname: null,
            minDuration: 0,
        }
    }

    AccountID() {
        return this.accountID
    }

    SetAccountID(id: string): void {
        this.accountID = id
        window.__lsAID = id
    }

    WebsiteID(): string {
        return this.websiteID
    }

    TrackID(): string {
        return `${this.accountID}.${this.websiteID}`
    }

    SetWebsiteID(id: string) {
        this.websiteID = id
        window.__lsWID = id
    }

    Get(name: string): ISettings {
        return this.settings[name]
    }

    Set(name: string, value: string): void {
        this.settings[name] = value
    }
}

export { Options, IOptions }
