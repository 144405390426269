import { debug } from "./../../common/tools"
import { defaultConditionEvents, getKindFromEventName } from "./typedef"
import { Utils, IUtils } from "./../utils/Utils"
import { Activity } from "./../activity"

type IActivity = typeof Activity
export interface IEventsStream {
    Add(name: string, obj: any, time?: number): void
    All(): []
    Enable(): void
    Events: () => []
    Length(): number
    Clear(): void
    Disable(): void
}

const ctxLog = (...args) => {
    debug("EventsStream", ...args)
}

const eventLogData = (name, obj, time) => {
    if (!obj) {
        return null
    }
    let e = { t: time }
    if (obj.x) e.x = obj.x
    if (obj.y) e.y = obj.y
    if (obj.json_data) e.json_data = obj.json_data
    if (obj.location) e.location = obj.location
    if (obj.width) e.width = obj.width
    if (obj.height) e.height = obj.height
    if (obj.txt) e.txt = obj.txt
    if (obj.value) e.value = obj.value

    return e
}

export const validEventProperties = (obj = {}) => {
    if (obj.x && obj.x < 0) {
        return false
    }

    if (obj.y && obj.y < 0) {
        return false
    }

    return true
}
export class EventsStream implements IEventsStream {
    events: [] = []
    conditions = null
    utils: IUtils
    enabled = true
    activity = null

    constructor({ conditions, utils, activity }: { conditions: any; utils: IUtils; activity: IActivity }) {
        this.Clear()
        this.conditions = conditions
        this.utils = utils
        this.enabled = true
        this.activity = activity
    }

    Enable() {
        this.enabled = true
    }

    Disable() {
        this.enabled = false
    }

    IsEmpty() {
        return this.events.length === 0
    }

    Events() {
        return this.events
    }

    Length() {
        return (this.events || []).length
    }

    Clear() {
        this.events = []
    }

    isEnabled() {
        return this.enabled
    }

    All() {
        const r = [...this.events]
        this.events = []
        return r as []
    }

    Dump(max: number) {
        const r = [...this.events.splice(0, max)]
        return r
    }

    Join(events: []) {
        this.events = [...this.events, ...events]
    }

    Add(name: string, obj: any, time: number = this.utils.Time.Duration()): void {
        let e = {
            kind: getKindFromEventName(name),
            time: time,
        }
        if (obj) {
            if (obj.el) e.el = obj.el
            if (obj.path) e.path = obj.path
            if (obj.txt) e.txt = obj.txt
            if (obj.x) e.x = parseInt(obj.x, 10)
            if (obj.y) e.y = parseInt(obj.y, 10)
            if (obj.json_data) e.json_data = JSON.stringify(obj.json_data)
            if (obj.location) e.location = obj.location
            if (obj.value) e.value = obj.value
            if (obj.width) e.width = parseInt(obj.width, 10)
            if (obj.height) e.height = parseInt(obj.height, 10)
        }

        const disabledBeforeConditionCheck = !this.isEnabled()
        this.conditions.CheckEventData({ name, ...e })
        const enabled = this.isEnabled()

        if (enabled) {
            if (disabledBeforeConditionCheck && this.conditions.EventsConditions().includes(name)) {
                // If stream was disabled before condition check and open after and then omit this event.
                // It's needed for properly rested utils.Time after conditions fulfilled.
                ctxLog(name, "omit event with previous disabled events stream")
                return
            }
            const isValid = validEventProperties(obj)
            if (!isValid) {
                return
            }

            const seq = this.utils.EventSeq.Next()
            e.seq = seq
            this.events.push(e)
            this.activity.applyEvent(name, time)
            if (!obj || !obj.omitLog) {
                ctxLog(name, eventLogData(name, obj, time))
            }
        }
    }
}
