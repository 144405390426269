import { debug } from "../../common/tools"

class Handler {
    public name: string

    constructor() {
        this.name = ""
        this.supportShadow = false
    }

    public debug(...args) {
        debug(this.name, ...args)
    }

    public setName(name) {
        this.name = name
    }

    public isDOMListener() {
        return false
    }

    public setSupportShadow(support) {
        this.supportShadow = support
    }
}

class DOMHandler extends Handler {
    public isDOMListener() {
        return true
    }
}

export { Handler, DOMHandler }
