export const TYPE_INT = "int"
export const TYPE_FLOAT = "float"
export const TYPE_BOOL = "bool"
export const TYPE_STRING = "str"

export const TYPES = [TYPE_INT, TYPE_FLOAT, TYPE_BOOL, TYPE_STRING]

export const isObject = (input) => {
    if (input && !Array.isArray(input) && typeof input === "object" && input instanceof Object) {
        return true
    } else return false
}

function isNumber(n) {
    return typeof n === "number" && !isNaN(n)
}

export function findSuffix(suffix, str) {
    const startIndex = str.lastIndexOf(suffix)

    const strLength = str.length
    const condition = str.slice(startIndex, startIndex + strLength) === suffix

    const meta = []

    if (condition) {
        meta.push(startIndex, strLength)
    }

    return [condition, ...meta]
}

export function castValue(value) {
    const valueFloat = parseFloat(value)
    const valueInt = parseInt(value)
    const valueNumber = isNumber(Number(value))

    if (valueNumber && (valueFloat === valueInt)) {
        if (valueMatchWithType(valueInt, TYPE_INT)) {
            return [valueInt, TYPE_INT]
        }
        return []
    }

    if (valueNumber && valueMatchWithType(valueFloat, TYPE_FLOAT)) {
        return [valueFloat, TYPE_FLOAT]
    }

    if (valueMatchWithType(value, TYPE_STRING)) {
        return [value, TYPE_STRING]
    }

    if (valueMatchWithType(value, TYPE_BOOL)) {
        return [value, TYPE_BOOL]
    }

    return []
}

export function valueMatchWithType(value, type) {
    switch (type) {
        case TYPE_STRING:
            return typeof value === "string"
        case TYPE_INT:
            return isNumber(value) && Number.isInteger(value)
        case TYPE_FLOAT:
            return isNumber(value)
        case TYPE_BOOL:
            return typeof value === "boolean"

        default:
            return false
    }
}
