const shallowequal = require("shallowequal")

export function map(data: any, cb: (value: any, key: any) => void): any[] {
    const result: any[] = []
    if (["string", "number"].indexOf(typeof data) > -1) {
        return result
    }
    for (let key in data) {
        const value = data[key]
        const newV = cb(value, key)
        result.push(newV)
    }
    return result
}

export function isEmpty(obj: any): boolean {
    if (obj == null) return true
    if (obj.length > 0) return false
    if (obj.length === 0) return true

    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) return false
    }
    return true
}
export function isEqual(x: any, y: any): boolean {
    return shallowequal(x, y)
}

export function isDOMNode(o: any) {
    if (!o) return false
    return typeof Node === "object" ? o instanceof Node : true && typeof o === "object" && typeof o.nodeType === "number" && typeof o.nodeName === "string"
}

export function isDOMElement(o: any) {
    return isDOMNode(o) && typeof HTMLElement === "object"
        ? o instanceof HTMLElement // DOM2
        : true && typeof o === "object" && o !== null && o.nodeType === 1 && typeof o.nodeName === "string"
}

export function lastIndexOf(input: [], condition: (x: any) => boolean): number {
    for (let i = input.length - 1; i >= 0; i -= 1) {
        const element = input[i]
        if (condition(element)) {
            return i
        }
    }
    return -1
}
