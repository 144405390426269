import forEach from "for-each"
import { getKindFromEventName } from "./events/typedef";
import { debug } from "../common/tools";

const ctxLog = (...args) => {
    debug("handlers", ...args)
}

class Handlers {
    constructor() {
        this.handlers = {}
        this.shadowHandlersEvents = []
    }

    Register(name, handler) {
        handler.setName(name)
        this.handlers[name] = handler
        if (handler.supportShadow) {
            this.shadowHandlersEvents.push(name)
        }
    }

    CleanUp() {
        forEach(this.handlers, (handler, name) => handler.Unregister())
    }

    Init() {
        let initialState = []
        forEach(this.handlers, (handler, name) => {
            if (handler.isDOMListener && handler.isDOMListener()) {
                handler.Unregister()
            }

            let r = handler.Register()
            if (r) {
                if (Array.isArray(r)) {
                    initialState = initialState.concat(r)
                } else {
                    initialState.push({ kind: getKindFromEventName(name), ...r })
                }
            }
        })
        return initialState
    }

    Get(name) {
        return this.handlers[name]
    }

    IsSet(name) {
        return this.handlers[name] != null
    }

    AddShadowListeners(root) {
        this.shadowHandlersEvents.forEach(event => {
            const handler = this.Get(event)
            if (!handler || typeof handler.AddListener !== "function") {
                ctxLog("Error: AddListener does not exists")
                return
            }

            handler.AddListener(root)
        })
    }

    RemoveShadowListeners(root) {
        this.shadowHandlersEvents.forEach(event => {
            const handler = this.Get(event)
            if (!handler || typeof handler.RemoveListener !== "function") {
                ctxLog("Error: RemoveListener does not exists")
                return
            }

            handler.RemoveListener(root)
        })
    }
}

export { Handlers }
