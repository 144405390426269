import { DOMHandler } from "./handler"
import { safe } from "../../common/tools"

class MouseUpHandler extends DOMHandler {
    constructor({ utils, eventsStream }) {
        super()
        this.utils = utils
        this.eventsStream = eventsStream

        this.eventFunc = (e) => safe(this.Handler.bind(this), e)
    }

    Register() {
        window.addEventListener("mouseup", this.eventFunc, true)
    }

    Unregister() {
        window.removeEventListener("mouseup", this.eventFunc, true)
    }

    Handler(e) {
        this.eventsStream.Add(this.name, {
            x: e.pageX,
            y: e.pageY,
            path: this.utils.DOM.FullPath(e.target),
            el: this.utils.DOM.ElementName(e.target),
        })
    }
}

export { MouseUpHandler }
