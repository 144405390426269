import { DOMHandler } from "./handler"
import { Ticker } from "../../common/ticker"
import { Throttle } from "../../common/throttle"
import { debug } from "../../common/tools"

class LocationChangeHandler extends DOMHandler {
    constructor({ utils, eventsStream, aquireInterval, hooks }) {
        super()
        this.utils = utils
        this.hooks = hooks
        this.eventsStream = eventsStream
        this.lastLocation = ""
        this.throttle = new Throttle({
            id: this.name + ".throttle",
            interval: aquireInterval,
            limit: 2, // 4 per second
            onLimit: () => {
                debug("LocationChange", "throttled")
            },
        })
        this.watchedEvents = {}
        this.bindedHandler = this.Handler.bind(this)
    }

    Register() {
        this.throttle.Start()
        window.addEventListener("popstate", this.bindedHandler)
        if (!window.history) return
        this.watchedEvents = {
            pushState: this.hooks.Create(window.history, "pushState", {
                after: this.bindedHandler,
            }),
            replaceState: this.hooks.Create(window.history, "replaceState", {
                after: this.bindedHandler,
            }),
        }
    }

    Unregister() {
        this.throttle.Stop()
        window.removeEventListener("popstate", this.bindedHandler)
        this.watchedEvents.pushState && this.hooks.Unbind(this.watchedEvents.pushState)
        this.watchedEvents.replaceState && this.hooks.Unbind(this.watchedEvents.replaceState)
    }

    addEvent(force = false) {
        const location = window.location.href
        if (force || this.lastLocation != location) {
            this.lastLocation = location

            this.eventsStream.Add(this.name, {
                location: location,
            })
        }
    }

    Handler(opts) {
        let force = false
        if (opts && opts.force !== null) {
            force = opts.force
        }
        if (force) {
            this.addEvent(force)
        } else {
            this.throttle.Use(() => this.addEvent(force))
        }
    }
}

export { LocationChangeHandler }
