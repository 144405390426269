import { DOMHandler } from "./handler"
import { Ticker } from "../../common/ticker"

function windowHeight() {
    return window?.innerHeight || document?.documentElement?.clientHeight || document?.body?.clientHeight || 0
}

function windowYScroll() {
    return window?.pageYOffset || document?.body?.scrollTop || document?.documentElement?.scrollTop || 0
}

function documentHeight() {
    return Math.max(
        document?.body?.scrollHeight || 0,
        document?.documentElement?.scrollHeight || 0,
        document?.body?.offsetHeight || 0,
        document?.documentElement?.offsetHeight || 0,
        document?.body?.clientHeight || 0,
        document?.documentElement?.clientHeight || 0,
    )
}

function scrollPercent() {
    return ((windowYScroll() + windowHeight()) / documentHeight()) * 100
}

class WindowScrollHandler extends DOMHandler {
    constructor({ utils, eventsStream, aquireInterval }) {
        super()
        this.startTime = null
        this.lastPosition = { x: 0, y: 0 }
        this.topDepth = { percent: 0 }
        this.position = { x: 0, y: 0 }
        this.intervalID = 0

        this.utils = utils
        this.eventsStream = eventsStream
        this.ticker = new Ticker({ interval: aquireInterval })

        this.eventFunc = this.Handler.bind(this)
    }

    Register() {
        this.startTime = null
        this.Handler()
        this.Trigger()

        window.addEventListener("scroll", this.eventFunc, true)
        this.ticker.Start(this.Trigger.bind(this))

        return this.Get()
    }

    Unregister() {
        this.startTime = null
        window.removeEventListener("scroll", this.eventFunc, true)
        this.ticker.Stop()
    }

    Trigger() {
        const { x, y } = this.lastPosition
        if (x == this.position.x && y == this.position.y) {
            this.startTime = null
            return
        }

        this.lastPosition = { x: this.position.x, y: this.position.y, json_data: this.position.json_data }
        this.eventsStream.Add(this.name, this.position, this.startTime)
        this.startTime = null
        this.position.value = undefined
    }

    Handler(e) {
        if (!this.startTime) {
            this.startTime = this.utils.Time.Duration()
        }

        const scrollX = parseInt(pageXOffset || (document?.documentElement?.clientWidth ? document?.documentElement?.scrollLeft : document?.body?.scrollLeft), 10)
        const scrollY = parseInt(pageYOffset || (document?.documentElement?.clientHeight ? document?.documentElement?.scrollTop : document?.body?.scrollTop), 10)

        this.position = {
            x: scrollX,
            y: scrollY,
            json_data: { v: this.utils.Time.Duration() - this.startTime },
            value: this.position.value || undefined,
        }

        const greatestScrollPercent = this.calcGreatestScrollPercentage()

        if (greatestScrollPercent) {
            this.position.value = greatestScrollPercent.toString()
        }
    }

    calcGreatestScrollPercentage() {
        const calculated = parseInt(scrollPercent(), 10)
        if (calculated > this.topDepth.percent || !this.topDepth.percent) {
            this.topDepth.percent = calculated
            return this.topDepth.percent
        }

        return undefined
    }

    Get() {
        this.Handler()

        const { x, y, value, json_data } = this.position
        return {
            x: Math.max(x, 0),
            y: Math.max(y, 0),
            value: value,
            json_data: JSON.stringify(json_data),
        }
    }
}

export { WindowScrollHandler }
