import { Utils } from "./utils/utils"

interface PageViewInfo {
    title: string
}

interface Location {
    origin: string
    href: string
    referrer: string
    base: string
}

interface Options {
    baseURL: string | ((string) => string)
}

interface Props {
    utils: Utils
}

class PageView {
    private id: number | string;
    private creationTimestamp: number;
    private isNewSession: boolean;
    private utils: Utils
    private pageViewOptions: Options

    constructor({ utils }: Props) {
        this.id = -1
        this.creationTimestamp = 0
        this.isNewSession = false
        this.utils = utils || new Utils()
        this.pageViewOptions = {} as Options
    }

    ID(): number | string {
        return this.id
    }

    CreationTimestamp(): number {
        return this.creationTimestamp
    }

    IsNewSession(): boolean {
        return this.isNewSession
    }

    SetID(id: string) {
        this.id = id
    }

    SetCreationTimestamp(timestamp: number) {
        this.creationTimestamp = timestamp
    }

    SetSessionState(newSession: boolean) {
        this.isNewSession = newSession
    }

    SetPageViewOptions(opts: Options) {
        this.pageViewOptions = opts || {} as Options
    }

    GetPageViewInfo(opts: PageViewInfo) {
        const [w, h] = this.utils.getWindowSize()

        return {
            ...this.utils.getSystemInfo(),
            utm: this.utils.getUTMParams(),
            page_view: {
                title: opts.title || document.title,
                viewport: {
                    width: window.innerWidth,
                    height: window.innerHeight,
                },
                location: this.location(),
                page: {
                    width: w,
                    height: h,
                    icon: this.getFaviconURL(),
                },
            },
        }
    }


    location(): Location {
        const base = this.GetBaseURL()

        const location: Location = {
            origin: window.location.origin,
            href: window.location.href,
            referrer: document.referrer,
            base,
        }

        if (this.pageViewOptions.baseURL && base) {
            try {
                location.origin = new URL(base).origin
            } catch {
                location.origin = base
            }

            try {
                location.href = new URL(new URL(window.location.href).pathname, base).href
            } catch {}
        }

        return location
    }

    GetBaseURL(): string {
        const { baseURL: baseURLOption } = this.pageViewOptions

        if (baseURLOption && typeof baseURLOption == "string") {
            return baseURLOption
        }

        let baseURL = this.utils.getBaseURL()

        if (baseURLOption && typeof baseURLOption == "function") {
            baseURL = baseURLOption(baseURL)
        }

        return baseURL
    }

    getFaviconURL(): string {
        const href = this.utils.findFavIconHref()
        if (href != "" && href[0] == "/") {
            return this.GetBaseURL() + href
        }

        return href
    }
}

export { PageView }
