import { Backoff } from "../../common/backoff"
import { debug } from "../../common/tools"
import { events } from "../events/typedef"
import { Handler } from "./handler"

const TECHNOLOGY_SHOPIFY = "shopify"
const TECHNOLOGY_INTERCOM = "intercom"
const TECHNOLOGY_LIVECHAT = "livechat"

const ctxLog = (...args) => {
    debug("TechnologyScan", ...args)
}

export class TechnologyScanHandler extends Handler {
    constructor(eventsStream) {
        super()
        this.backoff = new Backoff({
            backOffIntervalMs: 500,
            backOffMaxMs: 3000,
        })

        this.technologies = {
            [TECHNOLOGY_SHOPIFY]: () => {
                return window.Shopify && window.Shopify.theme && true
            },
            [TECHNOLOGY_INTERCOM]: () => {
                return window.Intercom && true
            },
            [TECHNOLOGY_LIVECHAT]: () => {
                return window.LiveChatWidget && true
            },
        }

        this.eventsStream = eventsStream
    }

    Register() {}

    Unregister() {}

    Init() {
        this.scan()
    }

    scan() {
        ctxLog("start scanning...")
        const technologiesToTest = Object.keys(this.technologies)
        const technologies = {}

        this.backoff.start(
            () => {
                ctxLog("scanning tick...")

                technologiesToTest.forEach((technology, i) => {
                    ctxLog(`scan ${technology} technology`)
                    if (!this.technologies[technology]) {
                        technologiesToTest.splice(i, 1)
                        return
                    }

                    const testPassed = !!this.technologies[technology]()
                    if (testPassed) {
                        technologiesToTest.splice(i, 1)
                    }

                    technologies[technology] = testPassed
                })

                return !technologiesToTest.length
            },
            () => {
                ctxLog("scanned technologies: ", JSON.stringify(technologies))

                this.eventsStream.Add(events.TECHNOLOGY, {
                    json_data: technologies,
                })
            },
        )
    }
}
