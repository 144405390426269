import { DOMHandler } from "./handler"
import { Ticker } from "../../common/ticker"
import { safe } from "../../common/tools"

class WindowResizeHandler extends DOMHandler {
    constructor({ utils, eventsStream, aquireInterval }) {
        super()
        this.lastValue = { w: 0, h: 0 }
        this.value = { w: 0, h: 0 }

        this.utils = utils
        this.eventsStream = eventsStream
        this.ticker = new Ticker({ interval: aquireInterval })

        this.eventHandler = (e) => safe(this.Handler.bind(this), e)
    }

    Register() {
        window.addEventListener("resize", this.eventHandler, true)
        this.ticker.Start(this.handleInterval.bind(this))
    }

    Unregister() {
        window.removeEventListener("resize", this.eventHandler, true)
        this.ticker.Stop()
    }

    handleInterval() {
        const { w, h } = this.lastValue

        if (w == this.value.w && h == this.value.h) {
            return
        }
        this.lastValue = { w: this.value.w, h: this.value.h }

        this.eventsStream.Add(this.name, { width: this.value.w, height: this.value.h })
    }

    Handler(e) {
        let w = window.innerWidth || 0
        const h = window.innerHeight || 0

        //TODO: better solution (https://trello.com/c/eme2zLk0/1851-safari-zoom-event-pinchin-pinchout)
        const zoomIn = window.innerWidth < window.screen.width
        if (/iPad|iPhone|iPod/.test(navigator.userAgent) && zoomIn) {
            w = window.screen.width
        }

        this.value = {
            w: w,
            h: h,
        }
    }
}

export { WindowResizeHandler }
